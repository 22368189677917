// import lib
import isEmpty from './isEmpty';

export const percentageCalculation = (price: any, percentage: any) => {
    price = parseFloat(price);
    percentage = parseFloat(percentage)

    if (!isEmpty(price)) {
        return price - (price * (percentage / 100))
    }
    return 0
}

export const balanceConvention = (balance: any, conventionAmt: any) => {
    balance = parseFloat(balance);
    conventionAmt = parseFloat(conventionAmt)
    if (!isEmpty(conventionAmt)) {
        return balance * conventionAmt
    }
    return 0
}
export const calPrice = (priceData: any, type: any) => {
    try {
        let total: any = 0,
            totalQty = 0,
            totalPrice = 0;
        if (type == "price") {
            for (let data of priceData) {
                total = total + data.price;
            }
            if (total > 0) {
                return total;
            }
        }
        if (type == "quantity") {
            for (let data of priceData) {
                total = total + data.quantity;
            }
            if (total > 0) {
                return  parseFloat(total)
               // return parseFloat(total).toFixed(6);
            }
        }
        if (type == "checksell") {
            for (let data of priceData) {
                total = total + data.quantity * data.price;
                totalQty = totalQty + data.quantity;
            }
            if (total > 0) {
                return total / totalQty;
            }
        }

        if (type == "Totalprice") {
            for (let data of priceData) {
                totalPrice = totalPrice + data.orgquantity * data.price;
            }
            return totalPrice;
        }

        if (type == "TotalpriceBM") {
            for (let data of priceData) {
                totalPrice =
                    totalPrice + data.orgquantity * data.price + data.comissionFee;
            }
            return totalPrice;
        }

        if (type == "orgquantity") {
            for (let data of priceData) {
                total = total + data.orgquantity;
            }
            if (total > 0) {
                return parseFloat(total);
            }
        }

        if (type == "avgorgqty") {
            for (let data of priceData) {
                total = total + data.orgquantity * data.price;
                totalQty = totalQty + data.orgquantity;
            }
            if (total > 0) {
                return total / totalQty;
            }
        }

        return 0;
    } catch (err) {
        console.log(err, "----3333");

        return 0;
    }
}
export const precentConvetPrice = (price: any, percentage: any) => {
    price = parseFloat(price);
    percentage = parseFloat(percentage)

    if (!isEmpty(price)) {
        return price * (percentage / 100)
    }
    return 0
}

export const interestByDays = (price: any, rate: any, days: any) => {
    price = parseFloat(price);
    rate = parseFloat(rate)
    days = parseFloat(days);

    if (!isEmpty(price) && !isEmpty(rate) && !isEmpty(days)) {
        return ((price * (rate / 100)) / days)
    }
    return 0
}

/** 
 * Calculate Without Service Fee
*/
export const withoutServiceFee = ({ price, serviceFee }: any) => {
    price = parseFloat(price)
    serviceFee = parseFloat(serviceFee)
    return price - (price * (serviceFee / 100))
}

export const decreaseValue = (start: any, end: any) => {
    try {
        if (!isEmpty(start) && !isEmpty(end)) {
            return (start - end) / (start * 100)
        }

        return 0
    } catch (err) {
        return 0
    }
}
export const completionValue = (start: any, end: any) => {
    try {
        if (!isEmpty(start) && !isEmpty(end)) {
            return (end / start) * 100
        }

        return 0
    } catch (err) {
        return 0
    }
}
