export interface TradePairState {
    firstCurrencySymbol: string,
    firstCurrencyId: number,
    firstFloatDigit: number,
    secondCurrencySymbol: number,
    secondCurrencyId: number,
    secondFloatDigit: number,
    botstatus: string,
    tikerRoot:string,
    _id: string
}

export const initialState: any = {}