import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';
const countrycitystatejson = require('countrycitystatejson')


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  private countryData = countrycitystatejson;

  getUserApi() {
    return this.http.get(environment.userApiUrl + 'api/user/profile')
  }

  getUserSettingApi() {
    return this.http.get(environment.userApiUrl + 'api/user/setting')
  }

  apigetSiteSetting() {
    return this.http.get(environment.userApiUrl + 'api/user/siteSetting')
  }

  updateThemeApi(payload: any) {
    return this.http.put(environment.userApiUrl + 'api/user/theme', payload)
  }

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: any, state: string) {
    return this.countryData.getCities(country, state);
  }
  updateProfile = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/user/profile', data)
  }

  changepassword = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/changePassword', data)
  }

  changeNewPhone = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/phoneChange', data)
  }

  verifyNewPhone = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/user/phoneChange', data)
  }

  addbank = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/bankdetail', data)
  }

  getbankdetails = () => {
    return this.http.get(environment.userApiUrl + 'api/user/bankdetail')
  }

  deletebank = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/user/bankdetail', data)
  }
  sendOTP = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/sendOTP', data)
  }

  deactivateSendOTP = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/deactivate/otp', data)
  }

  verifyOTP = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/verifyOtp', data)

  }

  get2facode = () => {
    return this.http.get(environment.userApiUrl + 'api/user/2fa')
  }

  update2facode = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/user/2fa', data)
  }

  disable2facode = (data: any) => {
    return this.http.patch(environment.userApiUrl + 'api/user/2fa', data)
  }

  sandboxkycotp = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/sandboxkycotp', data)
  }


  sandboxkycverifyotp = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/sandboxkycverifyotp', data)
  }


  sandboxkycpanadharlink = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/sandboxkycpanstatus', data)
  }

  getLoginHistory = (params: any) => {
    return this.http.get(environment.userApiUrl + 'api/user/loginHistory', { params })
  }

  getsupportcategory = () => {
    return this.http.get(environment.userApiUrl + 'api/user/getSupportCategory')
  }

  createnewticket = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/support', data)
  }

  getsupporticket = () => {
    return this.http.get(environment.userApiUrl + 'api/user/support')
  }

  replyticket = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/user/support', data)
  }

  closeTicket = (data: any) => {
    return this.http.patch(environment.userApiUrl + 'api/user/support', data)
  }

  addcontactus = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/addContactus', data)
  }

  addsubscribe = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/newSubscribe', data)
  }

  getNotification = (params: any) => {
    return this.http.get(environment.userApiUrl + 'api/user/notificationHistory', { params })
  }

  unreadCount = () => {
    return this.http.get(environment.userApiUrl + 'api/user/unread_count')
  }

  getKycCountries() {
    return this.http.get(environment.userApiUrl + 'api/user/kycCountries')
  }

  getValidDocuments(id: string) {
    return this.http.get(environment.userApiUrl + `api/user/validDocuments/${id}`)
  }

  submitKyc(data: any) {
    return this.http.put(environment.userApiUrl + `api/user/kyc`, data)
  }

  deleteAccount = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/user/delete-account', data)
  }
  isUserExists(phoneNo: string): Observable<{ status: boolean }> {
    return this.http.get<{ status: boolean }>(
      `${environment.userApiUrl}api/user/IsUserExists/${phoneNo}`
    );
  }
}
