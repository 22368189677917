<div class="bot-order" *ngIf="!isPrevious">
  <div class="bot_preview_between">
    <div *ngIf="closeType == 'sellMarket'">
      <div class="bot-order-head">
        <h4>Stop DCA Bot</h4>
        <button class="bot-clear" (click)="goPrevious()">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <h3 class="bot_preview_title">Select stop bot conditions</h3>
          <div class="bot_preview_box">
            <div>
              <h6>Sell at market price</h6>
              <p>Stop the bot's operations completely.</p>
              <!-- <p>
                Sell 0.0025 ETH forUSDT at market price and move the bot to
                history.
              </p> -->
            </div>
            <!-- <div class="bot-stop-detail">
              <div>
                <p>Total PNL(Buy&Sell)</p>
                <p>-0.40USDT/</p>
              </div>
              <div>
                <p>Fee</p>
                <p class="red_txt">-4.06%</p>
              </div>
            </div> -->
          </div>

          <div class="bgcolor d-flex align-items-center gap-2">
            <button *ngIf="loader!=true" class="primary_btn" (click)="handleSellSubmit()" [disabled]="loader"><span>Confirm</span></button>
            <button *ngIf="loader==true" class="primary_btn" [disabled]="loader"><span>Loading...</span></button>

            <button class="continue" (click)="goPrevious()"><span>Cancel</span></button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="closeType == 'close'">
      <div class="bot-order-head">
        <h4>Close DCA Bot</h4>
        <button class="bot-clear" (click)="goPrevious()">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <h3 class="bot_preview_title">Select Close bot conditions</h3>
          <div class="bot_preview_box">
            <div>
              <h6>Close Bot</h6>
              <p>
                Cancel all orders & Open orders will be closed and the
                current amount in trade currency will be held in your
                balance.
              </p>
            </div>
          </div>

          <div class="bgcolor d-flex align-items-center gap-2">
            <button class="primary_btn" (click)="cancelOrder()" [disabled]="loader"><span>Confirm</span></button>
            <button class="continue" (click)="goPrevious()"><span>Cancel</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dcaorderplace *ngIf="isPrevious" orderStep="three"></app-dcaorderplace>