import { Component, Renderer2, inject, ElementRef } from '@angular/core';
import { Cookies } from 'src/app/lib/cookies';
import { AuthService } from 'src/app/services/auth.service';
import { UserFacade } from '../../store/user';
import { UserSettingFacade } from 'src/app/store/usersetting';
import { TradePairFacade } from 'src/app/store/tradepair';
import { botSocket } from 'src/app/app.module';
import { BotService } from 'src/app/services/bot.service';
import { ToastrService } from 'ngx-toastr';
import isEmpty from 'src/app/lib/isEmpty';
interface Profile {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-header-bot',
  templateUrl: './header-bot.component.html',
  styleUrls: ['./header-bot.component.css']
})
export class HeaderBotComponent {
  profileDetails: Profile = {
    firstName: '',
    lastName: '',
    email: ''
  };
  theme = ''

  pairData: any = {}
  dcaData: any = []
  loggedIn: boolean = false
  private readonly userFacade: UserFacade = inject(UserFacade);
  constructor(
    private cookie: Cookies,
    private renderer: Renderer2,
    private service: AuthService,
    private botService: BotService,
    private elementRef: ElementRef,
    private userSettingFacade: UserSettingFacade,
    private botFacade: TradePairFacade,
    private botSocket: botSocket,
    private toastr: ToastrService,
  ) {
    if (this.cookie.isLogin()) {
      this.loggedIn = true
    }
    this.getprofile()
  }

  ngOnInit() {
    this.getUserSetting()
    this.getSinglePair()
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        if(!isEmpty(pair)) {
          this.pairData = pair
        this.fetchDetails()
        this.botSocket.on('runningOrder', this.fetchDetailsWS.bind(this))
        }
      },
      error: (err: any) => { }
    })
  }
  fetchDetails() {
    let reqData = {
      botType: "GENIE",
      exchange: this.pairData?.botstatus,
      pair: `${this.pairData?.firstCurrencySymbol}${this.pairData?.secondCurrencySymbol}`,
    };
    this.botService.getOpenOrders(reqData).subscribe({
      next: (v: any) => {
        this.dcaData = v.result
      },
      error: (err: any) => {
        if ([400, 404].includes(err.status)) {
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  fetchDetailsWS(result: any) {
    this.dcaData = result.data
  }
  onClickToggleClass() {
    this.renderer.addClass(document.body, 'light_theme');
  }

  signOut(): void {
    this.service.logoutApi()
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.profileDetails.email = data.email
          this.profileDetails.firstName = data.firstName
          this.profileDetails.lastName = data.lastName
        },

        complete: () => { }
      })
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }

  ngAfterViewInit() {
    // Use jQuery to handle the click event
    $(this.elementRef.nativeElement).find('.header__btn').click(() => {
      // console.log('Button clicked using jQuery!');
      $(this.elementRef.nativeElement).toggleClass("header__btn--active");
      $(".header").toggleClass("header--active");
      $(".sidebar").toggleClass("sidebar--active");
      $(".home_menu_bar").toggleClass("sidebar--active");
    });
  }

}
