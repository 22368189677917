import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { OnrampWebSDK } from '@onramp.money/onramp-web-sdk';

import isEmpty from 'src/app/lib/isEmpty';

import { ToastrService } from 'ngx-toastr';

import { CurrencyFacade } from 'src/app/store/currency';
import { WalletFacade } from 'src/app/store/wallet';
import { environment } from 'src/environments/environment';
import { WalletService } from 'src/app/services/wallet.service';
import { UserService } from 'src/app/services/user.service';

import { CookieService } from 'ngx-cookie-service';

import { SpotService } from 'src/app/services/spot.service';
import { PairFacade } from 'src/app/store/pair';
import { TradePairFacade } from 'src/app/store/tradepair';
import { PriceCnvFacade } from 'src/app/store/pricecnv';

import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs';

@Component({
  selector: 'app-onramp-money-home',
  templateUrl: './onramp-money-home.component.html',
  styleUrls: ['./onramp-money-home.component.css']
})
export class OnrampMoneyHomeComponent {
  SdkOptions: any = {};
  token: any;
  form!: FormGroup;
  isLoading: boolean = false;
  serverErrors: any = {};
  submitted: boolean = false;
  fbLoading: boolean = false;
  selectedCurrency: any
  convertPrice: number = 1
  // Redux state
  currency: any = [];
  pairData: any = {}
  wallet: any = [];
  coinDataName: any
  coinOptions: any = [];
  selectedCoin: any = {};
  pairId: any
  modalData: any = {};
  showModal: boolean = false;
  // Onramper modal
  onramperParams: any = {
    themeData: '',
    coinSymbol: '',
    address: '',
    fiatSymbol: '',
    fiatAmount: ''
  };
  searchText: any

  constructor(
    private _fb: FormBuilder,
    private currencyFacade: CurrencyFacade,
    private walletFacade: WalletFacade,
    private toastr: ToastrService,
    private walletService: WalletService,
    private cookieService: CookieService,
    private SpotService: SpotService,
    private pairFacade: PairFacade,
    private tradePairFacade: TradePairFacade,
    private priceCnvFacade: PriceCnvFacade,
    private UserService: UserService
  ) {
    this.initForm();
  }

  ngOnInit() {

    // this.getPairList()
    this.usdtPrice()
    this.initialCall();
    this.token = this.cookieService.get('user_token');

  }



  // Initialize form with validation rules
  initForm() {
    this.form = this._fb.group({
      coin: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(1)]],
      bankPaymentType: ['', Validators.required],
      transactionId: ['', Validators.required],
      image: [null, Validators.required],
      stakeDuration: [{ value: '365 Days', disabled: true }],
      agreement: [false, Validators.requiredTrue],
      //phoneNo: ['', Validators.required]
      phoneNo: ['',  [Validators.required, Validators.pattern('^[0-9]{10}$')], [this.phoneNoValidator.bind(this)]]
    });
  }

  // get usdt conversion price
  usdtPrice() {
    if ( this.form.value === 'CRED') {
      this.convertPrice = 1
      return
    }
    this.priceCnvFacade.pricecnv$.subscribe({
      next: (response: any) => {

        if (response && response.length > 0) {
          const conversion = response.find(
            (item: any) => item.baseSymbol == 'INR' && item.convertSymbol == 'USDT'
          )
          if (conversion)
            this.convertPrice = parseFloat(conversion.convertPrice)
        }
      },
      error: (error: any) => {
        // console.log(error)
      }
    })
  }

  // Filtered list based on search text
  filteredCoinOptions() {
    return this.coinOptions.filter((option: any) => {
      option.firstCurrencySymbol.toLowerCase().includes(this.searchText.toLowerCase())
    });
  }


  // Async call to retrieve wallet data
  async initialCall() {
    this.getWallet();
  }

  // Getters for validation convenience in template
  get coin() {
    return this.form.get('coin');
  }

  get fiatAmount() {
    return this.form.get('amount');
  }

  get bankPaymentType() {
    return this.form.get('bankPaymentType');
  }

  get transactionId() {
    return this.form.get('transactionId');
  }
  get phoneNo() {
    return this.form.get('phoneNo');
  }

  get image() {
    return this.form.get('image');
  }

  get agreement() {
    return this.form.get('agreement');
  }

  // // Method to change selected currency and perform any related logic
  // getCurrencyName(event: Event) {

  //   const selectElement = event.target as HTMLSelectElement;

  //   this.selectedCurrency = JSON.parse(selectElement.value)._id;
  //   this.coinDataName = JSON.parse(selectElement.value).firstCurrencySymbol


  // }


  changeCurrency(coiId: any) {
    this.pairId = coiId

  }


  // Validate fiat amount and address
  validation(fiatAmount: string, address: string): string {
    if (parseFloat(fiatAmount) <= 0) {
      return 'Please enter a valid amount';
    } else if (isEmpty(address)) {
      return 'Address is empty';
    }
    return '';
  }



  phoneNoValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    return this.UserService.isUserExists(control.value).pipe(
      map((response) => {
        if (!response.status) {
          return { phoneExists: true };
        }
        return null;
      }),
      catchError(() => of(null))
    );
  }

  // Form submission handler
  submitForm() {
    this.submitted = true;

    // Display error if the form is invalid
    if (!this.form.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }

    const { amount, bankPaymentType, transactionId, coin , phoneNo  } = this.form.value;
    const image = this.form.get('image')?.value;
    // const coinname = this.coinDataName;

    this.walletService.fiatDeposit(
      amount,
      image,
      bankPaymentType,
      transactionId,
      coin,
      phoneNo,
      this.token,
      '64ce387f89cf2f0813778cb1'
    ).subscribe({
      next: (response) => {
        this.toastr.success('Transaction successful!');
        this.modalData = response.data; // Set the response data to modalData
        this.showModal = true; // Show the modal
        this.resetForm();
      },
      error: (error) => {
        this.toastr.error('Transaction failed. Please try again.');
        console.error('Transaction error:', error);
      }
    });
  }





  // Handle file input change for image upload
  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.form.patchValue({ image: file });
    }
  }

  // Reset form to initial state
  resetForm() {
    this.submitted = false;
    this.form.reset();
    this.selectedCoin = {};
  }

  // Toggle modal visibility
  toggleModal() {
    this.showModal = false;
    this.fbLoading = false;
  }

  // Private function to get user wallet data
  private getWallet() {
    this.SpotService.getPairList().subscribe({
      next: (data: any) => {
        if (data) {

          this.coinOptions = data.result
          this.wallet = data.result
        }
      },
      error: (err) => {
        console.error('Error fetching wallet data:', err);
      }
    });
  }





  //   // Private function to get available currencies
  //   private getCurrencies() {
  //     this.currencyFacade.currency$.subscribe({
  //       next: (data: any) => {
  //         if (data && data.length > 0) {
  //           this.currency = data;

  //           const coinOptions: any[] = [];

  //           data.forEach((item: any) => {
  //             if (item && item.type === 'crypto') {
  //               const selectedCoin = this.wallet.find((el: any) => el.coin === item.coin);
  //               if (selectedCoin) {
  //                 coinOptions.push({
  //                   'label': item.coin,
  //                   'value': item.coin,
  //                   'type': 'crypto',
  //                   'coin': item.coin.toLowerCase(),
  //                   'network': '',
  //                   'address': !isEmpty(selectedCoin.address) ? selectedCoin.address : ""
  //                 });
  //               }
  //             } else if (item && item.type === 'token') {
  //               const selectedCoin = this.wallet.find((el: any) => el.coin === item.coin);
  //               if (selectedCoin) {
  //                 selectedCoin.tokenAddressArray.forEach((token: any) => {
  //                   const tokenName = `${item.coin} - ${token.tokenType.toUpperCase()}`;
  //                   coinOptions.push({
  //                     'label': tokenName,
  //                     'value': tokenName,
  //                     'type': 'token',
  //                     'coin': item.coin.toLowerCase(),
  //                     'network': token.tokenType,
  //                     'address': !isEmpty(token.address) ? token.address : ""
  //                   });
  //                 });
  //               }
  //             }
  //           });

  //           this.coinOptions = coinOptions;
  //           this.isLoading = true;
  //         }
  //       },
  //       error: (err) => {
  //         console.error('Error fetching currency data:', err);
  //       }
  //     });
  //   }
}
