<div class="bot-order" *ngIf="!showPrevious">
  <div class="bot_preview_between" *ngIf="!startBot">
    <div>
      <div class="bot-order-head">
        <h4>preview DCA Bot</h4>
        <button class="bot-clear" (click)="handlePrevious()">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <h3 class="bot_preview_title">General settings</h3>
          <div class="bot_preview_box">
            <div>
              <span>Exchange</span><span class="border_line"></span><span>Cred</span>
            </div>
            <div>
              <span>Pair</span><span class="border_line"></span><span>{{botDetails?.pairName}}</span>
            </div>
            <div>
              <span>Strategy</span><span class="border_line"></span><span>Long</span>
            </div>
            <div>
              <span>Investment</span><span class="border_line"></span><span>{{botDetails?.investment}} USDT</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <h3 class="bot_preview_title mt-2">Bot settings</h3>
            <div class="bot_preview_box">
              <div>
                <span>Base order</span><span class="border_line"></span><span>{{botDetails?.firstbuyAmount}}
                  USDT</span>
              </div>
              <div>
                <span>Base order type</span><span class="border_line"></span><span>Limit</span>
              </div>
              <div>
                <span>Place base order</span><span class="border_line"></span><span>Immediately</span>
              </div>
              <div *ngIf="botDetails?.avgOrdAmt > 0">
                <span>Avg. orders</span><span class="border_line"></span><span>{{
                  botDetails?.avgOrdAmt}} USDT</span>
              </div>
              <div *ngIf="botDetails?.avgOrdAmt < 0">
                <span>Avg. orders</span><span class="border_line"></span><span>
                  0 USDT</span>
              </div>
              <div *ngIf="botDetails?.margincallLimit > 0">
                <span>Avg. orders qty</span><span class="border_line"></span><span>{{
                  botDetails?.margincallLimit
                  }}</span>
              </div>
              <div *ngIf="botDetails?.margincallLimit < 0">
                <span>Avg. orders qty</span><span class="border_line"></span>
                <span>
                  0 </span>
              </div>
              <div *ngIf="botDetails?.margincallLimit > 0">
                <span>Avg. orders step</span><span class="border_line"></span><span>{{
                  botDetails?.avgOrdStep
                  }}%</span>
              </div>
              <div *ngIf="botDetails?.margincallLimit < 0">
                <span>Avg. orders step</span><span class="border_line"></span><span>
                  0% </span>
              </div>
              <div *ngIf="botDetails?.margincallLimit > 0">
                <span>Amount multiplier</span><span class="border_line"></span>
                <span>x{{botDetails?.amtMultiplier}}</span>
              </div>
              <div *ngIf="botDetails?.margincallLimit < 0">
                <span>Amount multiplier</span><span class="border_line"></span>
                <span>0 </span>
              </div>
              <div *ngIf="botDetails?.margincallLimit > 0">
                <span>Step multiplier</span><span class="border_line"></span>
                <span>x{{botDetails?.stepMultiplier}}</span>
              </div>
              <div *ngIf="botDetails?.margincallLimit < 0">
                <span>Step multiplier</span><span class="border_line"></span>
                <span>0 </span>
              </div>
            </div>
          </div>
          <div>
            <h3 class="bot_preview_title mt-2">Position TP &amp; SL</h3>
            <div class="bot_preview_box">
              <div *ngIf="botDetails?.takeprofitRatio > 0">
                <span>TP price change</span><span class="border_line"></span><span>{{
                  botDetails?.takeprofitRatio
                  }}%</span>
              </div>
              <div *ngIf="botDetails?.takeprofitRatio < 0">
                <span>TP price change</span><span class="border_line"></span><span>
                  0 </span>
              </div>
              <div>
                <span>TP percentage of</span><span class="border_line"></span><span>Average price</span>
              </div>
              <div>
                <span>TP order type</span><span class="border_line"></span><span>Limit</span>
              </div>
              <div *ngIf="botDetails?.stopLoss > 0 && botDetails?.isstoploss">
                <span>SL price change</span><span class="border_line"></span><span>{{botDetails?.stopLoss}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bgcolor d-flex align-items-center gap-2">
        <button class="">
          <img src="../../../../assets/images/left_arrow.png" (click)="handlePrevious()" alt="Icon"
            class="img-fluid left_arrow" /></button>
        <button class="continue" (click)="submitForm(orderPlaceDet)"><span>Start Bot</span></button>
      </div>
    </div>
  </div>
  <div class="create-bot-head" *ngIf="startBot">
    <div class="bot-order-head">
      <h4>Create DCA Bot</h4>
    </div>
    <div class="create-bot">
      <img src="../../../../assets/images/startup.png" alt="start" title="start" />
      <!-- <img
        src="../../../../assets/images/completed.png"
        alt="start"
        title="start"
      /> -->
      <h6>Starting Up</h6>
      <p>Loading......</p>
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
          style="width: 70%">
          <span class="sr-only">70% Complete</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dcaorderplace *ngIf="showPrevious || closeBot" orderStep="one"></app-dcaorderplace>
<!-- <app-dcaorderplace *ngIf="closeBot" orderStep="one"></app-dcaorderplace> -->