<h2 class="dashbox_title">Buy Crypto with INR</h2>
<div class="container-fluid">
    <div class="row">
        <!-- <div *ngIf="!isLoading; else data">
            <app-loader></app-loader>
        </div>
        <ng-template #data> -->
        <!-- buy crypto form -->
        <div class="col-md-7">
            <form [formGroup]="form" (ngSubmit)="submitForm()">

                <!-- Currency Selection -->
                <div class="sign_group sign_group_input">
                    <label>Choose Currency</label>
                    <select class="sign_input"  formControlName="coin">
                        <option value="">Select Currency</option>
                        <!-- <option *ngFor="let option of coinOptions" [value]="option | json">
                            {{option.firstCurrencySymbol}}
                        </option> -->

                        <option value="CRED">CRED</option>
                    </select>
                    <div *ngIf="coin?.invalid && (coin?.touched || submitted)">
                        <span class="required" *ngIf="coin?.errors?.['required']">Currency is required</span>
                    </div>
                </div>
                <!-- INR Amount -->
                <div class="sign_group sign_group_input">
                    <label>Enter INR Amount</label>
                    <div class="primary_inp_grp">
                        <input type="text" class="sign_input" placeholder="Enter INR Amount" formControlName="amount" />
                    </div>
                    <div *ngIf="fiatAmount?.invalid && (fiatAmount?.touched || submitted)">
                        <span class="required" *ngIf="fiatAmount?.errors?.['required']">INR Amount is required</span>
                        <span class="required" *ngIf="fiatAmount?.errors?.['min']">Amount must be greater than 0</span>
                    </div>
                    <span class="required" *ngIf="serverErrors?.fiatAmount">{{ serverErrors.fiatAmount }}</span>
                </div>


                <!-- Phone  no-->
                <div class="sign_group sign_group_input">
                    <label>Enter phone No</label>
                    <div class="primary_inp_grp">
                        <input type="number" class="sign_input" placeholder="Enter phoneNo" formControlName="phoneNo" />
                    </div>
                    <div *ngIf="phoneNo?.invalid && (phoneNo?.touched || submitted)">
                        <span class="required" *ngIf="phoneNo?.errors?.['required']">Phone No is required</span>
                        <span class="required" *ngIf="phoneNo?.errors?.['pattern']">Phone No must be exactly 10 digits</span>
                        <!-- <span class="required" *ngIf="phoneNo?.errors?.['min']"> must be greater than 0</span> -->
                        <span class="required" *ngIf="form.get('phoneNo')?.errors?.['phoneExists']">
                          Phone number does not exist
                        </span>
                    </div>
                    <span class="required" *ngIf="serverErrors?.phoneNo">{{ serverErrors.phoneNo }}</span>
                </div>




                <!-- Bank Payment Type -->
                <div class="sign_group sign_group_input">
                    <label>Bank Payment Type*</label>
                    <select class="sign_input" formControlName="bankPaymentType">
                        <option value="">Select Payment Type</option>
                        <option value="IMPS">IMPS</option>
                        <option value="NEFT">NEFT</option>
                    </select>
                    <div
                        *ngIf="form.get('bankPaymentType')?.invalid && (form.get('bankPaymentType')?.touched || submitted)">
                        <span class="required" *ngIf="form.get('bankPaymentType')?.errors?.['required']">Payment type is
                            required</span>
                    </div>
                </div>

                <!-- Transaction ID -->
                <div class="sign_group sign_group_input">
                    <label>Transaction ID*</label>
                    <div class="primary_inp_grp">
                        <input type="text" class="sign_input" placeholder="Enter Transaction ID"
                            formControlName="transactionId" />
                    </div>
                    <div
                        *ngIf="form.get('transactionId')?.invalid && (form.get('transactionId')?.touched || submitted)">
                        <span class="required" *ngIf="form.get('transactionId')?.errors?.['required']">Transaction ID is
                            required</span>
                    </div>
                </div>

                <div class="form_note">
                    <label>Only UTR Number format is acceptable</label>
                </div>

                <!-- File Upload -->
                <div class="sign_group sign_group_input">
                    <label>Upload Your Receipt*</label>
                    <div class="primary_inp_grp custom_file_upload">
                        <input type="file" class="sign_input" (change)="onFileChange($event)"
                            accept=".png, .jpg, .jpeg, .pdf" />
                        <label>(.png/ .jpg/ .jpeg/ .pdf formats only)</label>
                    </div>
                    <img
                    src="assets/images/folder_icon.png"
                    class="img-fluid copy_icon"
                    alt="Copy"
                  />
                    <div *ngIf="form.get('image')?.invalid && (form.get('image')?.touched || submitted)">
                        <span class="required" *ngIf="form.get('image')?.errors?.['required']">Receipt upload is
                            required</span>
                    </div>
                </div>

                <!-- Stake Duration -->
                <div class="sign_group sign_group_input">
                    <label>Stake Duration</label>
                    <div class="primary_inp_grp">
                        <input type="text" class="sign_input" value="365 Days" formControlName="stakeDuration"
                            readonly />
                    </div>
                </div>

                <!-- Checkbox Agreement -->
                <div class="sign_group sign_group_checkbox">
                    <input id="remember" formControlName="agreement" type="checkbox" />
                    <label for="remember">
                        I have transferred funds from my own account and verified on Cred Bull. These funds have been
                        acquired
                        by me through legitimate sources.
                    </label>
                    <div *ngIf="form.get('agreement')?.invalid && (form.get('agreement')?.touched || submitted)">
                        <span class="required" *ngIf="form.get('agreement')?.errors?.['required']">You must agree to the
                            terms</span>
                    </div>
                </div>

                <!-- Form Buttons -->
                <div class="d-flex justify-content-center">
                    <button type="button" class="withdraw_btn me-2 cursor-pointer" (click)="resetForm()">Cancel</button>
                    <button type="submit" class="withdraw_btn cursor-pointer">Buy</button>
                </div>
            </form>
        </div>
        <div class="col-md-5" *ngIf="showModal">
            <div class="dashbox">
                <h3>Transaction Details</h3>
                <p>Fiat Amount: {{ modalData.fiatAmount }}</p>
                <p>Rewards: {{ modalData.reward }}</p>
                <p>Total Coin value: {{ modalData.totalCoinvalue }}</p>
            </div>
        </div>
        <!-- </ng-template> -->
    </div>
</div>
