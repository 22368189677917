import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dcaorderplace',
  templateUrl: './dcaorderplace.component.html',
  styleUrls: ['./dcaorderplace.component.css']
})
export class DcaorderplaceComponent {
  @Input() orderStep: any
  @Input() botDetails: any
  @Input() orderPlaceDet: any
  @Input() posDetails: any
  @Input() closeType: any
}
