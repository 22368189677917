import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import isEmpty from 'src/app/lib/isEmpty';
import { BotService } from 'src/app/services/bot.service';

// Import facade
import { PairFacade } from '../../store/botpair'
import { AuthFacade } from 'src/app/store/auth';
import { WalletService } from 'src/app/services/wallet.service';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { TradePairFacade } from 'src/app/store/bottradepair';
import { Store } from '@ngrx/store';
import { SocketService } from 'src/app/services/socket.service';
import { dateTimeFormat } from 'src/app/lib/dateFilter';
import { firstCapital } from 'src/app/lib/capital';
import { toFixed, toFixedDown } from 'src/app/lib/roundOf';
import { botSocket, spotSocket } from 'src/app/app.module';
// import { TradeAssetFacade } from 'src/app/store/trade-asset';
// import { MarketPriceFacade } from 'src/app/store/marketprice';

@Component({
  selector: 'app-dcabot',
  templateUrl: './dcabot.component.html',
  styleUrls: ['./dcabot.component.css']
})
export class DCABOTComponent {
  isEmpty = isEmpty
  dateTimeFormat = dateTimeFormat
  firstCapital = firstCapital
  toFixedDown = toFixedDown
  toFixed = toFixed
  tikerRoot: any
  auth: any = {}
  pairData: any = {}
  posDetails: any = {}
  chartData: any = {}
  // botData: any = {}
  // botLOrders: any = {}
  chartStatus = false

  constructor(
    private route: ActivatedRoute,
    private service: BotService,
    private router: Router,
    private pairFacade: PairFacade,
    private botFacade: TradePairFacade,
    private authFacade: AuthFacade,
    private walletService: WalletService,
    private tradeAssetFacade: TradeAssetFacade,
    private spotSocket: spotSocket,
    private botSocket: botSocket,
    private socketService: SocketService,
    private store: Store<{ positionDetails: PositionDetailsFacade }>
  ) {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }
  ngOnInit() {
   
    this.getPairs()

    // window.onresize = () => this.isMobileLayout = window.innerWidth <= 1199;
    // window.onload = () => this.isMobileLayout = window.innerWidth <= 1199;
    this.route.paramMap.subscribe(params => {
      this.tikerRoot = params.get('tikerRoot');
      if (!isEmpty(this.tikerRoot)) {
        this.socketService.emit("subscribe", this.tikerRoot.replace("_", ""));
      }
    });

    this.getCurrPair();
    
    this.store.select('positionDetails').subscribe((posDet: any) => {
      console.log(posDet.positionDetails, '---------59',isEmpty(posDet.positionDetails))
      this.posDetails = posDet.positionDetails;
      // this.getBotHistoryCall(posDet.positionDetails)
    });
  }

  //get redux data
  getCurrPair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
      },
      error: (err: any) => { }
    })
  }

  fetchDetailsWS(result: any) {
    if (result.pairId == this.pairData._id) {
      console.log("chart 111111",this.pairData)
      this.fetchDCAChart(this.pairData)
    }
  }
  fetchOHWS(result: any) {
    if (result.pairId == this.pairData._id) {
      console.log("chart 22222",this.pairData)

      this.fetchDCAChart(this.pairData)
    }
  }
  
  // getBotHistoryCall(reqData: any) {
  //   try {
  //     this.service.getBotHistory({ botId: reqData._id, }).subscribe({
  //       next: (v: any) => {
  //         let entrypricetot = 0,
  //           exitpricetot = 0,
  //           exitpricetot_wof = 0,
  //           profit = "",
  //           finalQty = 0,
  //           totalOrgQty = 0,
  //           totalPrice = 0,
  //           investmentPrice = 0,
  //           totalPrice_wofee = 0,
  //           combuybnbUsdt = 0,
  //           comsellbnbusdt = 0,
  //           profitall = "";
  //         let index = 0;
  //         let result = v?.result
  //         if (!isEmpty(result) && result.length > 0) {
  //           for (let item of result) {
  //             let commission = 0,
  //               commissionUSDT = 0,
  //               feeUsdt = 0,
  //               commissionAsset = "";

  //             if (!isEmpty(item?.feeFilled)) {
  //               for (let feeitem of item?.feeFilled) {
  //                 commissionAsset = feeitem?.commissionAsset;
  //                 commission += parseFloat(feeitem?.commission);
  //                 commissionUSDT += parseFloat(feeitem?.commissionUSDT);
  //               }
  //               result[index].commissionAsset = commissionAsset;
  //               result[index].commission = commission;
  //               result[index].commissionUSDT = commissionUSDT;
  //             } else {
  //               result[index].commissionAsset = commissionAsset;
  //               result[index].commission = commission;
  //               result[index].commissionUSDT = commissionUSDT;
  //             }

  //             result[index].comissionFee = parseFloat(item.comissionFee);

  //             index++;
  //           }
  //           let quantityDecimal = result[0]?.quantityDecimal;
  //           finalQty = toFixedDown(finalQty, quantityDecimal);
  //           let priceDecimal = result[0].priceDecimal;
  //           //let priceDecimal = 6;

  //           if (exitpricetot > 0) {
  //             let exitovaluepop = 0;
  //             if (comsellbnbusdt == 0) {
  //               exitovaluepop =
  //                 exitpricetot - exitpricetot * result[0].takerCommission;
  //             } else {
  //               exitovaluepop = exitpricetot - comsellbnbusdt;
  //             }
  //             let profitall = exitovaluepop - combuybnbUsdt - investmentPrice;
  //             let profitpercentage = (profitall / investmentPrice) * 100;
  //             profitall = toFixedDown(profitall, priceDecimal);

  //             result[0].profitall = profitall;
  //             // result[0].profit = profit;
  //             result[0].profitpercentage = profitpercentage;
  //           }
  //           this.botData = result
  //         }
  //         this.botLOrders = v?.limitorders
  //       },
  //       error: (err: any) => {
  //         console.log(err, '-------76')
  //       },
  //       complete: () => { },
  //     });
  //   } catch (err) {
  //     console.log(err, "---81");
  //   }
  // };
  ngOnDestroy() {
    if (!isEmpty(this.tikerRoot)) {
      this.socketService.emit("unSubscribe", this.tikerRoot.replace("_", ""));
    }
  }
  getPairs() {
    this.service.getPairList()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (isEmpty(this.tikerRoot)) {
              let pair = `${v.result[0].firstCurrencySymbol}_${v.result[0].secondCurrencySymbol}`
              this.router.navigateByUrl("/dcabot/" + pair)
            } else {
              this.pairFacade.setPair(v.result)
              const selectedPair = v.result.find((el: any) => el.tikerRoot === this.tikerRoot.replace('_', ''))

              this.pairData = selectedPair;
              // this.fetchAssetByCurrency(selectedPair.firstCurrencyId, 'firstCurrency')
              this.fetchAssetByCurrency(selectedPair.secondCurrencyId, 'secondCurrency')
              this.service.setTradePair(selectedPair)
              this.fetchDCAChart(selectedPair)
              this.botSocket.on('runningOrder', this.fetchDetailsWS.bind(this))
              this.botSocket.on('tradeHistory', this.fetchOHWS.bind(this))

              // this.marketPriceFacade.setSpotMarketPrice(selectedPair)
              // this.service.getMarketPrice(selectedPair._id)

              //   this.pairs = v.result.reduce((acc: any[], curr: any) => {
              //     if (!acc[curr.secondCurrencySymbol]) {
              //       acc[curr.secondCurrencySymbol] = [];
              //       this.currencies.push(curr.secondCurrencySymbol)
              //     }
              //     acc[curr.secondCurrencySymbol].push(curr);
              //     return acc;
              //   }, {});

              //   this.favPairData = v.result.filter((element: any) => this.favPairList.includes(element.tikerRoot))
              //   this.activeCurrency = this.currencies[0]
            }
          }
        },
        error: (err: any) => {
        }
      })
  }
  fetchDCAChart(selectedPair: any) {
    console.log("selectedPairselectedPair",selectedPair)
    this.service.getOpenOChart({
      pair: selectedPair.tikerRoot,
      pairId: selectedPair._id,
      exchange: selectedPair.botstatus,
      botType: "GENIE",
    }).subscribe({
      next: (v: any) => {
        console.log(v, '-------116')
        this.chartStatus = true
        this.chartData = v.result
        // this.testVar = ["Test 1"]
      },
      error: (err: any) => {
        console.log(err, '-------117')
      },
      complete: () => { },
    });
  }

  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.auth.signedIn)
      return
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }
}
