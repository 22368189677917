<header class="header header--fixed">
  <div class="container">
    <div class="header__content">
      <nav class="navbar bg-body-dark navbar-expand-xl">
        <div class="d-flex align-items-center">
          <a class="navbar-brand header__logo" href="/">
            <img
              [src]="
                theme == 'dark'
                  ? 'assets/images/new-logo.svg'
                  : 'assets/images/new-logo.svg'
              "
              alt="Logo"
              class="img-fluid"
            />
          </a>
          <ul class="navbar-nav mobile_none home_navbar_middle">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/market-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Market</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/fee-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Fee</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/spot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Trade</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/launchpad"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Launchpad</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/dcabot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >DCA</a
              >
            </li> -->
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/subscriptionpage"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Subscription</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [href]="siteSetting.externalSiteUrl"
                target="_blank"
                >{{ siteSetting.externalSiteName }}</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/dashboard"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Dashboard</a
              >
            </li>
            <li class="nav-item mobile_none" *ngIf="loggedIn">
              <button
                class="btn startButton"
                data-bs-toggle="modal"
                data-bs-target="#new_bot_modal"
              >
                <img
                  [src]="
                    theme == 'dark'
                      ? 'assets/images/startboticon.svg'
                      : 'assets/images/startboticon.svg'
                  "
                  alt="Bot Status"
                  class="img-fluid"
                />
                Start New Bot
              </button>
            </li>
          </ul>
        </div>
        <button class="header__btn navbar-toggler" type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <app-theme class="dashboard_theme"></app-theme>
        <div class="home_menu_bar" tabindex="-1">
          <ul class="navbar-nav justify-content-end flex-grow-1 desktop_none">
            <li class="nav-item user_info_dropdown" *ngIf="loggedIn">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="sidebar__user-img">
                  <span>
                    {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                    }}{{
                      profileDetails.lastName | slice : 0 : 1 | uppercase
                    }}</span
                  >
                </div>

                <div class="sidebar__user-title">
                  <p>
                    {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/market-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Market</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/fee-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Fee</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/spot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Trade</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/launchpad"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Launchpad</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/dcabot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >DCA</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/subscriptionpage"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Subscription</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [href]="siteSetting.externalSiteUrl"
                target="_blank"
                >{{ siteSetting.externalSiteName }}</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/dashboard"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Dashboard</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/edit-profile"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Edit Profile</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/wallet"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Wallet</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/security"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Security</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                href="javascript:void(0)"
                routerLinkActive="active"
                (click)="signOut()"
                [routerLinkActiveOptions]="{ exact: true }"
                >Logout</a
              >
            </li>
          </ul>
          <ul
            class="navbar-nav justify-content-end flex-grow-1 align-item-center"
          >
            <li class="nav-item" *ngIf="!loggedIn">
              <a
                class="nav-link"
                routerLink="/signin"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Sign In</a
              >
            </li>
            <li class="nav-item" *ngIf="!loggedIn">
              <a
                class="nav-link primary_btn"
                routerLink="/signup"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Sign Up</a
              >
            </li>
            <li
              class="nav-item dropdown user_info_dropdown mobile_none"
              *ngIf="loggedIn"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="sidebar__user-img">
                  <span>
                    {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                    }}{{
                      profileDetails.lastName | slice : 0 : 1 | uppercase
                    }}</span
                  >
                </div>

                <div class="sidebar__user-title">
                  <p>
                    {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                  </p>
                </div>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" routerLink="/edit-profile"
                    ><i class="bi bi-pencil-square me-1"></i> Edit Profile</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/wallet"
                    ><i class="bi bi-wallet2 me-1"></i>Wallet</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/security"
                    ><i class="bi bi-shield-check me-1"></i>Security</a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="signOut()"
                    ><i class="bi bi-door-closed me-1"></i>Logout</a
                  >
                </li>
              </ul>
            </li>
            <li class="mobile_none">
              <app-theme></app-theme>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <!-- <div class="header__content">
    
    <a href="/" class="header__logo">
      <img src="assets/images/logo.svg" alt="" />
    </a>
    

    
    <button class="header__btn" type="button">
      <span></span>
      <span></span>
      <span></span>
    </button>

    
  </div> -->
</header>
<!-- end header -->
<div
  class="modal fade primary_modal"
  id="new_bot_modal"
  tabindex="-1"
  aria-labelledby="new_bot_modalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="new_bot_modalLabel">Start new bot</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="new_bot_panel">
          <a href="/dcabot">
            <div class="new_bot_panel_content">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="10" fill="#28BA74"></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24 28.8c-.66 0-1.2-.54-1.2-1.2V8.4c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v19.2c0 .66-.54 1.2-1.2 1.2Zm-13.2-1.2v-4.8c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v4.8c0 .66-.54 1.2-1.2 1.2-.66 0-1.2-.54-1.2-1.2Zm6-12v12c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2v-12c0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2Z"
                  fill="#fff"
                ></path>
              </svg>
              <h4>DCA Bot</h4>
            </div>
            <p>
              The Dollar-cost Averaging bot multiplies your gains and reduces
              risks in volatile markets.
            </p>
          </a>
          <a href="javascript:void(0)">
            <div class="new_bot_panel_content">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="10" fill="#6552EC"></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.6 8.1H8.4c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2h19.2c.66 0 1.2-.54 1.2-1.2V9.3c0-.66-.54-1.2-1.2-1.2ZM8.4 15.3h19.2c.66 0 1.2.54 1.2 1.2v1.2c0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2v-1.2c0-.66.54-1.2 1.2-1.2Zm0 6h19.2c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2Zm-.6 4.8h20.4c.336 0 .6.264.6.6 0 .336-.264.6-.6.6H7.8a.594.594 0 0 1-.6-.6c0-.336.264-.6.6-.6Z"
                  fill="#fff"
                ></path>
              </svg>
              <h4>GRID Bot</h4>
            </div>
            <p>
              The proven grid strategy bot maximizes your returns on the
              slightest price changes.
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
