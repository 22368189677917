import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Cookies } from 'src/app/lib/cookies';
import isEmpty from 'src/app/lib/isEmpty';
import { toFixedDown } from 'src/app/lib/roundOf';
import { TradePairFacade } from 'src/app/store/bottradepair';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { UserSettingFacade } from 'src/app/store/usersetting';

import { IChartingLibraryWidget, widget } from 'src/assets/charting_library/charting_library.min';
import { environment } from 'src/environments/environment';

const chartUrl = environment.spotApiUrl; // 'https://credspotapi.wearedev.team/' 


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent {
  @Input() chartData: any

  theme: 'Dark' | 'Light' = 'Dark'
  pairData: any = {}
  intervalId: any;

  public isMobileLayout = false;
  public isDestopLayout = false;

  backUrl = chartUrl + 'api/spot/chart';
  pair = "BNBUSDT";
  first_time = true
  private _tvWidget: IChartingLibraryWidget | null = null;

  posDetails: any = {}
  constructor(
    private tradePairFacade: TradePairFacade,
    private userSettingFacade: UserSettingFacade,
    private cookies: Cookies,
    private store: Store<{ positionDetails: PositionDetailsFacade }>

  ) { }

  ngOnInit() {
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1199;
    window.onload = () => this.isMobileLayout = window.innerWidth <= 1199;
    this.getThemeData(this.chartData)
    this.store.select('positionDetails').subscribe((posDet: any) => {
      this.posDetails = posDet.positionDetails;
    });
    console.log(this.chartData, '--------49')

  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // alert()
    // if (changes['testVar']) {
    //   console.log('testVartestVartestVar2', changes['testVar'].currentValue);
    // }

    console.log("59--------------------")
    console.log("60--------------------", changes)
    console.log("70--------------------", changes['chartData'].firstChange)

    if (changes['chartData'] && !changes['chartData'].firstChange) {
      console.log(changes['chartData'], changes['chartData'].firstChange, "SFD0sd0s0f")
      this.getThemeData(this.chartData)
    }
  }

  getThemeData(cData: any) {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        if (data.defaultTheme) {
          this.theme = data.defaultTheme === 'dark' ? 'Dark' : 'Light'
          this.getTradeData(cData)
        }
      },
      error: (err: any) => { }
    })
  }

  getTradeData(cData: any) {
    this.tradePairFacade.tradePair$.subscribe({
      next: (tradePair: any) => {
        if (!isEmpty(tradePair)) {
          let symbol = tradePair.firstCurrencySymbol + tradePair.secondCurrencySymbol;
          this.buildChart(symbol, cData)
        }
      },
      error: (err: any) => { }
    })
  }

  buildChart(pair: string, cData: any) {
    const widgetOptions: any = {
      symbol: pair,
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.backUrl),
      interval: '5',
      container_id: 'tv_chart_container3',
      library_path: '/assets/charting_library/',
      locale: 'en',
      disabled_features: ["use_loalstorage_for_settings", "display_market_status"],
      enabled_features: ["study_templates", "use_localstorage_for_settings"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      autosize: false,
      studies_overrides: {},
      loading_screen: { backgroundColor: "#00094a" },
      theme: this.theme,
      toolbar_bg: "#00094a",
      timezone: "Asia/Kolkata",
      overrides: {
        // "symbolWatermarkProperties.color": "##05051f",
        "paneProperties.background": "#00094a",
        "paneProperties.vertGridProperties.color": "transparent",
        "paneProperties.horzGridProperties.color": "transparent",
      },
    };

    if (this.theme == 'Light') {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);
    // Indicators Setup
    tvWidget.onChartReady(() => {
      if (!tvWidget) return; // Ensure widget exists
      const chart = tvWidget.activeChart();
      let previousStudies: any = [];
      const checkForStudiesUpdate = () => {
        const studies = chart.getAllStudies(); // Get applied indicators
        let inputs: any;

        const currentStudies = studies.map(study => {
          let studyDetails = chart.getStudyById(study.id);

          inputs = studyDetails.getInputValues()
          return {
            id: study.id,
            name: study.name,
            inputs: inputs

          }
        }).filter(study => study !== null);
        // Check if indicators or settings have changed
        if (JSON.stringify(previousStudies) !== JSON.stringify(currentStudies)) {
          console.log("Indicators Updated:", currentStudies);
          // Store updated indicators in localStorage
          localStorage.setItem("appliedIndicators", JSON.stringify(currentStudies));
          // Update previous state
          previousStudies = currentStudies;
        }
      };
      
      const savedIndicators = JSON.parse(localStorage.getItem("appliedIndicators") || "[]");
      for (let i = 0; i < savedIndicators.length; i++) {
        const inputValues = savedIndicators[i].inputs.map((input: { id: string; value: number }) => input.value);
        chart.createStudy(savedIndicators[i].name, false, false, inputValues);

      }
      // Check every 2 seconds for new indicators
      this.intervalId = setInterval(checkForStudiesUpdate, 2000);


    });
    //End Indicators Setup



    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => { },
          })
        );
        console.log(cData, '-------123', cData?.stoploss && cData?.stoploss?.percentage > 0 &&
          cData?.stoploss?.price > 0)

        console.log("---237", cData?.avgPrice, typeof cData?.avgPrice?.avgPrice)



        if (
          !isEmpty(cData) &&
          cData?.takeprofit?.price > 0
        ) {
          console.log('------128')
          let priceScale_Reset: any = tvWidget
            .activeChart()
            .getPanes()[0]
            .getMainSourcePriceScale();
          console.log(priceScale_Reset, '-----133')
          // priceScale_Reset.applyOptions({
          //   autoScale: true
          // });
          console.log('--------134')
          let avgprice = cData?.avgPrice.avgPrice;
          let lp;
          if (
            cData &&
            cData.stoploss &&
            cData.stoploss?.percentage > 0 &&
            cData.stoploss?.price > 0
          ) {
            lp = cData.stoploss.price;
          } else {
            if (
              cData &&
              cData?.avgCall &&
              cData?.avgCall.length > 0 &&
              cData?.avgCall[cData.avgCall?.length - 1].price
            ) {
              lp = cData.avgCall[cData.avgCall.length - 1].price;
            } else {
              lp = cData?.markPrice;
            }
          }
          let hp;
          if (parseFloat(cData.takeprofit.price) < parseFloat(cData?.markPrice)) {
            hp = cData?.markPrice;
          } else {
            hp = cData.takeprofit && cData.takeprofit.price;
          }
          hp = parseFloat(hp);
          lp = parseFloat(lp);
          console.log(lp, "-----lp");
          console.log(hp, "-----hp");
          if (lp > 0 && hp > 0) {
            let priceScale = tvWidget
              .activeChart()
              .getPanes()[0]
              .getRightPriceScales()[0];
            let a = priceScale.setVisiblePriceRange({
              from: toFixedDown(lp - (lp * 2) / 100, cData?.priceDecimal),
              to: toFixedDown(hp + (hp * 2) / 100, cData?.priceDecimal),
            });
          }
        }
        if (cData?.takeprofit?.price > 0) {
          console.log('------125')
          // Create an order line at the price of 95,000
          const chart = tvWidget.activeChart();
          const orderLine = chart.createOrderLine({});
          // Set the price using setPrice method
          orderLine.setPrice(cData.takeprofit.price);  // Set static price for the order line

          // Customize the order line
          orderLine.setLineStyle(0);  // 0 = solid, 1 = dotted, 2 = dashed, etc.
          orderLine.setLineLength(25); // Length of the order line (optional)
          orderLine.setBodyFont(`Order Line - ${cData.takeprofit.price}`); // Text to display on the order line
          orderLine.setQuantity(toFixedDown(cData.takeprofit.amount, 2)); // Quantity or other info (optional)
          orderLine.setBodyTextColor('#FF0000'); // Set text color
          orderLine.setLineColor('#FF0000'); // Set line color
          orderLine.setText(`TakeProfit - ${toFixedDown(cData.takeprofit.price)}`); // Replace "Order" with custom label
        }
        if (cData?.avgPrice && cData?.avgPrice?.avgPrice > 0) {
          // Create an order line at the price of 95,000
          const chart = tvWidget.activeChart();
          const orderLine = chart.createOrderLine({});
          // Set the price using setPrice method
          orderLine.setPrice(cData.avgPrice.avgPrice);  // Set static price for the order line

          // Customize the order line
          orderLine.setLineStyle(0);  // 0 = solid, 1 = dotted, 2 = dashed, etc.
          orderLine.setLineLength(25); // Length of the order line (optional)
          orderLine.setText("DCA"); // Replace "Order" with custom label
          // orderLine.setBodyFont(`Order Line - ${ cData.avgPrice.price } `); // Text to display on the order line
          orderLine.setQuantity(toFixedDown(cData.avgPrice.amount, 2)); // Quantity or other info (optional)
          // orderLine.setTextColor('#FF0000'); // Set text color
          orderLine.setLineColor('rgb(22, 112, 72)'); // Set line color
        }


        if (cData?.avgCall && cData.avgCall.length > 0) {
          for (let i = 0; i < cData.avgCall.length; i++) {
            let item = cData.avgCall[i]
            // Create an order line at the price of 95,000
            const chart = tvWidget.activeChart();
            const orderLine = chart.createOrderLine({});
            // Set the price using setPrice method
            orderLine.setPrice(item.price);  // Set static price for the order line

            // Customize the order line
            orderLine.setLineStyle(0);  // 0 = solid, 1 = dotted, 2 = dashed, etc.
            orderLine.setLineLength(25); // Length of the order line (optional)
            orderLine.setBodyFont(`Order Line - ${item.price}`); // Text to display on the order line
            orderLine.setText(`Averaging Call(${item.cntstart + i + 1}) - ${toFixedDown(item.price)} `); // Replace "Order" with custom label
            orderLine.setBodyTextColor('rgb(22, 112, 72)'); // Set text color
            orderLine.setLineColor('rgb(22, 112, 72)'); // Set line color
            orderLine.setQuantity(""); // Quantity or other info (optional)

          }
        }





        if (cData?.stoploss && cData?.stoploss?.percentage > 0 &&
          cData?.stoploss?.price > 0 && cData?.avgCall?.length == 0) {
          // Create an order line at the price of 95,000
          const chart = tvWidget.activeChart();
          const orderLine = chart.createOrderLine({});
          // Set the price using setPrice method
          orderLine.setPrice(cData.stoploss.price);  // Set static price for the order line

          // Customize the order line
          orderLine.setLineStyle(0);  // 0 = solid, 1 = dotted, 2 = dashed, etc.
          orderLine.setLineLength(25); // Length of the order line (optional)
          orderLine.setText(`StopLoss`);
          // orderLine.setBodyFont(`Order Line - ${ cData.takeprofit.price } `); // Text to display on the order line
          orderLine.setQuantity(toFixedDown(cData.stoploss.amount, 2)); // Quantity or other info (optional)
          // orderLine.setTextColor('#FF0000'); // Set text color
          orderLine.setLineColor('rgb(200, 7, 7)'); // Set line color
          orderLine.setBodyTextColor('rgb(200, 7, 7)'); // Set text color
        }
      });
    });

    window.addEventListener("beforeunload", () => clearInterval(this.intervalId));

  }

}
