import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { botSocket, spotSocket } from 'src/app/app.module';
import { fraction, longDecimals, nFormatter, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { BotService } from 'src/app/services/bot.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AuthFacade } from 'src/app/store/auth';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import { PairFacade } from 'src/app/store/botpair';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';

@Component({
  selector: 'app-dcabotpair',
  templateUrl: './dcabotpair.component.html',
  styleUrls: ['./dcabotpair.component.css']
})
export class DcabotpairComponent {

  toFixedDown = toFixedDown
  truncateDecimals = truncateDecimals
  longDecimals = longDecimals
  fraction = fraction
  nFormatter = nFormatter

  pairs: any = {}
  auth: any = {}
  searchPair: string = ''
  sorth = "asc";
  constructor(
    private pairFacade: PairFacade,
    private router: Router,
    private route: ActivatedRoute,
    private service: BotService,
    private authFacade: AuthFacade,
    private walletService: WalletService,
    private tradeAssetFacade: TradeAssetFacade,
    private positionDetails: PositionDetailsFacade,
    private spotSocket: spotSocket,
    private botSocket: botSocket
  ) {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }

  ngOnInit() {
    this.getPairs()
    // this.spotSocket.on('marketPrice', this.fetchMarkPrice)
  }
  // fetchMarkPrice(result: any) {
  //   console.log(result, '---------51')
  // if (result.pairId == this.tradePair._id) {
  // this.tradeData = [...result.data, ...data]
  // }
  // }
  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        if(pair.length && pair.length>0) {


        //   console.log(pair,"heello12313")
        // this.pairs  = pair.sort(function (a: any, b: any) {
        //   return +a?.change - +b?.change;
        // });
        let sortedPairs = [...pair];
        sortedPairs = sortedPairs.sort((a: any,b: any) => +a.change - +b.change);
        this.pairs = sortedPairs;
        console.log(this.pairs, "this.pairs")
        this.botSocket.on('marketPrice', this.pairMarkPrice.bind(this))
      }

       
      },
      error: (err: any) => { }
    })
  }
  pairMarkPrice(result: any) {
    let pairDet: any = []
    this.pairs.forEach((item: any) => {
      if (result.pairId == item._id) {
        // pairDet.push(item)
        pairDet.push({
          ...item,
          change: result.data.change,
          markPrice: result.data.markPrice,
        })
      } else {
        pairDet.push(item)
      }
    })

    if (this.sorth == "asc") {
      this.pairs = pairDet.sort(function (a: any, b: any) {
        return a.change - b.change;
      });
    } else {
      this.pairs = pairDet.sort(function (a: any, b: any) {
        return b.change - a.change;
      });
    }


//    this.pairs = pairDet
  }
  handleSearchPair(event: any) {
    this.searchPair = event.target.value.toUpperCase().replace('/', '')
  }
  changePair(pair: any) {
    const newPair = pair.firstCurrencySymbol + '_' + pair.secondCurrencySymbol
    const activePair = this.route.snapshot.params['tikerRoot']
    if (newPair == activePair) return;

    // this.socketService.emit("unSubscribe", activePair.replace("_", ""));
    this.botSocket.emit("unSubscribe", activePair.replace("_", ""));
    // this.router.navigateByUrl('/dcabot/' + newPair)
    window.location.href = '/dcabot/' + newPair
    this.service.setTradePair(pair)
    this.positionDetails.setPosition({})
    // this.fetchAssetByCurrency(pair.firstCurrencyId, 'firstCurrency')
    this.fetchAssetByCurrency(pair.secondCurrencyId, 'secondCurrency')

    // this.pairData = pair;
    // this.marketPriceFacade.setSpotMarketPrice(pair)
  }

  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.auth.signedIn) {
      return
    }
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }

  sort24h(sortby: any) {
   
    this.sorth = sortby == "asc" ? "desc" : "asc";
    if (this.sorth == "asc") {
      this.pairs.sort(function (a: any, b: any) {
        return a.change - b.change;
      });
    } else {
    this.pairs.sort(function (a: any, b: any) {
        return b.change - a.change;
      });
    }
  }

}
