export const environment = {
  production: true,
  userApiUrl: 'https://creduserapi.wearedev.team/',
  walletApiUrl: 'https://credwalletapi.wearedev.team/',
  spotApiUrl: 'https://credspotapi.wearedev.team/',
  launchpadApiUrl: 'https://credlaunchpadapi.wearedev.team/',
  botApiUrl: 'https://credbotapi.wearedev.team/',
  recaptcha: {
    siteKey: '6LdMuPImAAAAAHQkXQi3UKLe2vBSeA5LzEwIJsWc',
  },
  onramperApiKey: '5vRsHm3YMs0lTrL7JIzZYvjjjqtuyg',
  onrampAppID: 192431
};

// export const environment = {
//   production: true,
//   userApiUrl: 'https://userapi.credbull.in/',
//   walletApiUrl: 'https://walletapi.credbull.in/',
//   spotApiUrl: 'https://spotapi.credbull.in/',
//   launchpadApiUrl: 'https://launchpadapi.credbull.in/',
//   botApiUrl: 'https://botapi.credbull.in/',
//   recaptcha: {
//     siteKey: '6LdMuPImAAAAAHQkXQi3UKLe2vBSeA5LzEwIJsWc',
//   },
//   onramperApiKey: '5vRsHm3YMs0lTrL7JIzZYvjjjqtuyg',
//   onrampAppID: 192431
// };
