import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Store } from '@ngrx/store';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import isEmpty from 'src/app/lib/isEmpty';
import { PairFacade } from 'src/app/store/pair';
import { TradePairFacade } from 'src/app/store/tradepair';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import { BotService } from 'src/app/services/bot.service';
import { toFixed, toFixedDown } from 'src/app/lib/roundOf';
import { dateHours, dayOnly } from 'src/app/lib/dateFilter';
import { botSocket } from 'src/app/app.module';

@Component({
  selector: 'app-dcabotdetails',
  templateUrl: './dcabotdetails.component.html',
  styleUrls: ['./dcabotdetails.component.css'],
  animations: [
    trigger('contentAnimation', [

      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.5s ease-out', style({ height: '*', opacity: 1 }))
      ]),

      transition(':leave', [
        animate('.15s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class DcabotdetailsComponent {
  toFixedDown = toFixedDown
  toFixed = toFixed
  isCollapsed = true;
  isClose = false;
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleClose() {
    this.isClose = true
  }
  pairs: any = {}
  pairData: any = {}
  performance: any = {}
  botLOrders: any = {}
  posDetails: any = {}
  tradeData: any = {}
  constructor(
    private pairFacade: PairFacade,
    private botFacade: TradePairFacade,
    private service: BotService,
    private botSocket: botSocket,
    private store: Store<{ positionDetails: PositionDetailsFacade }>
  ) {
  }
  ngOnInit() {
    this.getPairs()
    this.getSinglePair()
    this.store.select('positionDetails').subscribe((posDet: any) => {
      this.posDetails = posDet.positionDetails;
      if (!isEmpty(posDet.positionDetails)) {
        this.getBotHistoryCall(posDet.positionDetails)
        this.botSocket.on('marketPrice', this.pairMarkPrice.bind(this))
      }
    });
  }
  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        this.pairs = pair
      },
      error: (err: any) => { }
    })
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
      },
      error: (err: any) => { }
    })
  }
  getBotHistoryCall(reqData: any) {
    try {
      this.service.getBotHistory({ botId: reqData._id, }).subscribe({
        next: (v: any) => {
          console.log(reqData?.tradeSetting?.Tradestrategy[0],"reqData?.tradeSetting?.Tradestrategy[0]")
          this.tradeData = reqData?.tradeSetting?.Tradestrategy[0]
          let result = v?.result
          let pairDetails = v?.pairData
          let limitorders = v?.limitorders
          let entrypricetot = 0,
            exitpricetot = 0,
            exitpricetot_wof = 0,
            profit = "",
            finalQty = 0,
            totalOrgQty = 0,
            totalPrice = 0,
            investmentPrice = 0,
            totalPrice_wofee = 0,
            combuybnbUsdt = 0,
            comsellbnbusdt = 0,
            profitall = "";
          let index = 0;
          let inorderBc = 0;
          for (let lItem of limitorders) {
            if (lItem.buyorsell == "buy") {
              entrypricetot += lItem.price * lItem.orgquantity
            }
            if (lItem.buyorsell == "sell") {
              inorderBc += lItem.orgquantity
            }
          }
          if (!isEmpty(result) && result.length > 0) {
            for (let item of result) {
              let commission = 0,
                commissionUSDT = 0,
                feeUsdt = 0,
                commissionAsset = "";
              if (item.buyorsell == "buy") {
                if (item.exchange == "BitMart") {
                  feeUsdt = parseFloat(item.comissionFee);
                }
                // entrypricetot += item.price * item.orgquantity + feeUsdt;

                finalQty += item.quantity;
                totalOrgQty += parseFloat(item.orgquantity);

                investmentPrice += item.orgquantity * item.price + feeUsdt;
                // for (let buyfeeItem of item?.feeFilled) {
                //   if (
                //     buyfeeItem?.commissionAsset == "BNB" &&
                //     item.exchange == "Binance"
                //   ) {
                //     combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                //   }
                // }

                totalPrice_wofee += item.quantity * item.price;
              }
              if (item.buyorsell == "sell") {
                // if (combuybnbUsdt > 0) {
                //   exitpricetot += item.price * item.orgquantity;
                // } else {
                //   exitpricetot += item.price * item.quantity;
                // }
                exitpricetot += item.price * item.quantity;
                let totalOrgQtysell = 0;
                for (let item of result) {
                  if (item.buyorsell == "buy") {
                    totalOrgQtysell += parseFloat(item.orgquantity);
                  }
                }
                exitpricetot_wof += item.price * totalOrgQtysell;
                // for (let sellfeeItem of item?.feeFilled) {
                //   if (
                //     sellfeeItem?.commissionAsset == "BNB" &&
                //     item.exchange == "Binance"
                //   ) {
                //     comsellbnbusdt += parseFloat(sellfeeItem?.commissionUSDT);
                //   }
                // }
              }

              if (!isEmpty(item?.feeFilled)) {
                for (let feeitem of item?.feeFilled) {
                  commissionAsset = feeitem?.commissionAsset;
                  commission += parseFloat(feeitem?.commission);
                  commissionUSDT += parseFloat(feeitem?.commissionUSDT);
                }
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              } else {
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              }

              result[index].comissionFee = parseFloat(item.comissionFee);

              index++;
            }
            let quantityDecimal = result[0]?.quantityDecimal;
            finalQty = toFixedDown(finalQty, quantityDecimal);
            let priceDecimal = result[0].priceDecimal;
            //let priceDecimal = 6;

            if (exitpricetot > 0) {
              let exitovaluepop = 0;
              if (comsellbnbusdt == 0) {
                exitovaluepop =
                  exitpricetot - exitpricetot * result[0].takerCommission;
              } else {
                exitovaluepop = exitpricetot - comsellbnbusdt;
              }
              let profitall = exitovaluepop - investmentPrice;
              let profitpercentage = (profitall / investmentPrice) * 100;
              profitall = toFixedDown(profitall, priceDecimal);

              result[0].profitall = profitall;
              // result[0].profit = profit;
              result[0].profitpercentage = profitpercentage;
            }

            let noofdays: any = dayOnly(result[0].createdAt, result[0].currentDate);
            let exovaluewfee = 0;
            let inorderQc = 0;
            if (limitorders.length > 0) {
              for (let limitorder of limitorders) {
                // if (limitorder.buyorsell == "buy") {
                //   inorderBc += limitorder.price * limitorder.quantity;
                // }

                if (limitorder.buyorsell == "sell") {
                  inorderQc += limitorder.quantity;
                }
              }
            }
            let pairData = pairDetails.find(
              (el: any) => el._id.toString() == result[0].pairId.toString()
            );
            exovaluewfee = pairData.markPrice * finalQty;
            //with fee
            exovaluewfee = exovaluewfee - combuybnbUsdt;
            let finalProfit: any = exovaluewfee - investmentPrice;
            let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
           // finalProfit = toFixedDown(finalProfit, priceDecimal);
            finalProfit = toFixedDown(finalProfit, 6);


            //witout fee
            let exovaluewofee = pairData.markPrice * totalOrgQty;
            let finalProfit_wofee = exovaluewofee - investmentPrice;
            let finalProfitPercentageWo =
              (finalProfit_wofee / investmentPrice) * 100;
            finalProfit_wofee = toFixedDown(finalProfit_wofee, priceDecimal);

            let avgdailyProfit = toFixed(parseFloat(finalProfit) / noofdays, 2);

            // buy and sell fee
            let TakeprofitPriceOrdervaluebseFee =
              exovaluewfee - exovaluewfee * result[0].takerCommission;

            let finalProfit_bsfee =
              TakeprofitPriceOrdervaluebseFee - investmentPrice;

            let finalProfitPercentage_bs =
              (finalProfit_bsfee / investmentPrice) * 100;
            finalProfit_bsfee = toFixedDown(finalProfit_bsfee, priceDecimal);

            //End 

            let exOrderVal = 0;
            exOrderVal = pairData.markPrice * finalQty;
            exOrderVal = exOrderVal - combuybnbUsdt;
            exOrderVal = exOrderVal - (exOrderVal * result[0].takerCommission)
            let finPro: any = exOrderVal - investmentPrice;
            //finPro = toFixed(finPro, priceDecimal);
            finPro = toFixedDown(finPro, 6);

            let finProPercentage = (finPro / investmentPrice) * 100;


            this.performance = {
              pnl: toFixedDown(finPro, 2),
              pnlval: toFixedDown(finProPercentage, 2),
              orderBc: toFixedDown(inorderBc, 6),
              orderQc: toFixedDown(inorderQc, 4),
              // orderBc: finalQty,
              // orderQc: toFixedDown(result[0].investmentAmount - totalPrice, 4),
              orderVal: toFixedDown(entrypricetot, 4),
              invBc: 0,
              invQc: result[0].investmentAmount,
              invVal: result[0].investmentAmount,
              txn: result?.length,
              pairId: result[0].pairId,
              botHistory: result,
              //createdAt: result[0].createdAt,
              createdAt: dateHours(result[0].createdAt),
              _id: result[0]._id,
              dcaPrice: toFixedDown(totalPrice_wofee / finalQty, priceDecimal),
              avgdailyProfit: avgdailyProfit,
              avgdailyProfitval: toFixed(
                (avgdailyProfit / result[0].investmentAmount) * 100,
                2
              ),
              priceDecimal: priceDecimal,
              quantityDecimal: quantityDecimal,
            }
            // setBotData(result);
            this.botLOrders = limitorders;
          }
        },
        error: (err: any) => {
          console.log(err, '-------error')
        },
        complete: () => { },
      });
    } catch (err) {
      console.log(err, "00087");
    }
  };
  pairMarkPrice(result: any) {
    let performanceList: any = {}
    if (this.performance.pairId == result.pairId) {
      console.log(this.performance, '------294')
      let botResult = this.performance.botHistory;

      let finalQty = 0,
        feeUsdt = 0,
        combuybnbUsdt = 0,
        totalOrgQty = 0,
        investmentPrice = 0;
      let entrypricetot = 0

      let inorderBc = 0;
      for (let lItem of this.botLOrders) {
        if (lItem.buyorsell == "buy") {
          entrypricetot += lItem.price * lItem.orgquantity
        }
        if (lItem.buyorsell == "sell") {
          inorderBc += lItem.orgquantity
        }
      }
      for (let item of botResult) {
        if (item.buyorsell == "buy") {
       
          finalQty += item.orgquantity;
          totalOrgQty += item.orgquantity;
          investmentPrice += item.orgquantity * item.price + feeUsdt;

        }
      }
      let quantityDecimal = botResult[0]?.quantityDecimal;
      finalQty = toFixedDown(finalQty, quantityDecimal);
      let priceDecimal = botResult[0].priceDecimal;
      let takerCommission = botResult[0].takerCommission;
      let exOrderVal = 0;
      exOrderVal = result.data.markPrice * finalQty;
      // exOrderVal = exOrderVal - combuybnbUsdt;
     //  exOrderVal = exOrderVal - (exOrderVal * takerCommission)
      let finalProfit: any = exOrderVal - investmentPrice;
     // finalProfit = toFixed(finalProfit, priceDecimal);
      finalProfit = toFixedDown(finalProfit, 6);

      let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

      console.log(finalProfit, '---------finalProfitpair')
      console.log(finalProfitPercentage, '---------finalProfitPercentagepair')
      console.log(finalQty, '---------finalQtypair')
      console.log(exOrderVal, '---------exOrderValpair')

      let noofdays: any = dayOnly(
        botResult[0].createdAt,
        botResult[0].currentDate
      );

      let avgdailyProfit = toFixed(
        parseFloat(finalProfit) / noofdays,
        2
      );

      let inorderQc = 0;
      if (this.botLOrders.length > 0) {
        for (let limitorder of this.botLOrders) {
          // if (limitorder.buyorsell == "buy") {
          //   inorderBc += limitorder.price * limitorder.quantity;
          // }

          if (limitorder.buyorsell == "sell") {
            inorderQc += limitorder.quantity;
          }
        }
      }
      performanceList = {
        ...this.performance,
        pnl: finalProfit,
        pnlval: finalProfitPercentage,
        orderBc: toFixedDown(inorderBc, 6),
        orderQc: toFixedDown(inorderQc, 4),
        orderVal: toFixedDown(entrypricetot, 4),
        invBc: 0,
        invQc: botResult[0].investmentAmount,
        invVal: botResult[0].investmentAmount,
        txn: botResult?.length,
        createdAt: dateHours(botResult[0].createdAt),
        _id: botResult[0]._id,
        avgdailyProfit: avgdailyProfit,
        avgdailyProfitval: toFixed(
          (avgdailyProfit / botResult[0].investmentAmount) * 100,
          2
        ),
      };
    } else {
      // console.log("2189---", item);
      performanceList = this.performance;
    }
    this.performance = performanceList
  }
}
