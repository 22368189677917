import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { botSocket } from 'src/app/app.module';
import { calPrice } from 'src/app/lib/calculation';
import { firstCapital } from 'src/app/lib/capital';
import { dateHours, dateHoursClose, dateHourswithTime, dateTimeFormat } from 'src/app/lib/dateFilter';
import isEmpty from 'src/app/lib/isEmpty';
import { toFixed, toFixedDown } from 'src/app/lib/roundOf';
import { BotService } from 'src/app/services/bot.service';
import { AuthFacade } from 'src/app/store/auth';
import { PairFacade } from 'src/app/store/pair';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { TradePairFacade } from 'src/app/store/tradepair';
import { getDisplayedPages } from 'src/app/lib/pagination';

@Component({
  selector: 'app-dcaorderhistory',
  templateUrl: './dcaorderhistory.component.html',
  styleUrls: ['./dcaorderhistory.component.css']
})
export class DcaorderhistoryComponent {
  @ViewChild('firstTab') firstTab!: ElementRef;
  getDisplayedPages: number[] = []


  toFixedDown = toFixedDown
  toFixed = toFixed
  parseFloat = parseFloat
  isEmpty = isEmpty
  dateTimeFormat = dateTimeFormat
  firstCapital = firstCapital
  pairData: any = {}
  pairs: any = []
  sumProfit: any = { sumpnl: 0, sumpnlval: 0 }
  totCurrent: any = 0
  data: any = []
  history: any = []
  botData: any = {}
  botDatapop: any = {}
  botLOrders: any = {}
  sumProfitHis: any = {
    sumpnl: 0,
    sumpnlval: 0,
    curVal: 0,
    inv: 0,
    pnlVal: 0,
  }
  auth: any = {}
  isPos: any = {}
  isPosition: any = false


  filter: any = {
    page: 1,
    limit: 20,
    totalPages: 0,
    paginator: []
  }

  constructor(
    private service: BotService,
    private toastr: ToastrService,
    private botFacade: TradePairFacade,
    private pairFacade: PairFacade,
    private positionDetails: PositionDetailsFacade,
    private botSocket: botSocket,
    private authFacade: AuthFacade,
    private renderer: Renderer2
  ) {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }


  ngOnInit() {

    this.getSinglePair()
    this.getPairs()
  }
  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        this.pairs = pair
        this.isPosition = false
      },
      error: (err: any) => { }
    })
  }
  getSinglePair() {

    this.botFacade.tradePair$.subscribe({

      next: (pair: any) => {
        if (!isEmpty(pair)) {

          this.isPosition = false
          this.pairData = pair
          this.fetchDetails()
          this.fetchOrderHistory(this.filter)
          this.botSocket.on('runningOrder', this.fetchDetailsWS.bind(this))
          this.botSocket.on('tradeHistory', this.fetchOHWS.bind(this))
          this.botSocket.on('marketPrice', this.pairMarkPrice.bind(this))
        }

      },
      error: (err: any) => { }
    })
  }
  fetchDetails() {

    let reqData = {
      botType: "GENIE",
      exchange: this.pairData?.botstatus,
      pair: `${this.pairData?.firstCurrencySymbol}${this.pairData?.secondCurrencySymbol}`,
    };
    this.service.getOpenOrders(reqData).subscribe({
      next: (v: any) => {
        let pairList = []
        for (let [index, item] of v.result.entries()) {
          let avgPrice = item.avgPrice / item.quantity;
          let priceDecimal = item.priceDecimal;
          // let finalQty: any = calPrice(item.filled, "quantity");
          let finalQty: any = calPrice(item.filled, "orgquantity");
          let totalOrgQty: any = calPrice(item.filled, "orgquantity");
          let combuybnbUsdt = 0;
          finalQty = toFixedDown(finalQty, item?.quantityDecimal);

          // console.log(finalQty, "------finalQty");
          let investmentPrice: any = calPrice(item.filled, "Totalprice");
          if (this.pairData._id.toString() == item.pairId.toString()) {

            this.positionDetails.setPosition(item)
            this.getBotHistoryCall(item)
            this.isPosition = true
          }

          let singlePair = this.pairs.find(
            (el: any) => el._id.toString() == item.pairId.toString()
          );
          console.log(singlePair, "-----singlePairsinglePair");

          let exovaluewfee;

          exovaluewfee = singlePair.markPrice * finalQty;

          // exovaluewfee = exovaluewfee - combuybnbUsdt;
          // exovaluewfee = exovaluewfee - (exovaluewfee * item.takerCommission)
          exovaluewfee = exovaluewfee;

          let finalProfit: any = exovaluewfee - investmentPrice;
          // finalProfit = finalProfit - (finalProfit * item.takerCommission)
          finalProfit = toFixedDown(finalProfit, priceDecimal);
          let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

          console.log(finalQty, "his1");
          console.log(totalOrgQty, "his3");
          console.log(exovaluewfee, "his3");
          console.log(singlePair.markPrice, "his4");
          console.log(finalProfit, "his5");
          console.log(investmentPrice, "his6");
          console.log(combuybnbUsdt, "his7");

          let investment = 0;
          for (let data of item.entryPrice) {
            investment = investment + data.orgquantity * data.price;
          }

          let cmp1 = singlePair.markPrice * item.quantity;

          let cmp =
            singlePair.markPrice * item.quantity - avgPrice * item.quantity;

          // console.log(investment, "investment----");
          // console.log(finalProfit, "finalProfit----");
          let curVal = investment + parseFloat(finalProfit);
          // console.log(curVal, "-------641");
          let txnDate = dateHours(item.createdAt)
          pairList.push({
            ...item,
            currentvalue: toFixedDown(curVal, item?.quantityDecimal),
            cmp: singlePair.markPrice,
            cmp1: cmp1,
            // investment: investment ? toFixed(investment, 4) : 0.0,
            investment: investment ? toFixedDown(investment, item?.quantityDecimal) : 0.0,
            pl: finalProfitPercentage ? toFixedDown(finalProfitPercentage, 4) : 0.0,
            pnlval: toFixedDown(finalProfit, 4),
            txnDate: txnDate,
            // profitinprice: profitinprice,
            referralCode: item.referralCode,
            takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
            index: index,
          });
        }
        const sumOfFields = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.currentvalue);
        }, 0);

        const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pl);
        }, 0);

        const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pnlval);
        }, 0);

        this.totCurrent = sumOfFields;
        this.sumProfit = {
          sumpnl: sumOfFields_pnl,
          sumpnlval: sumOfFields_pnl_val,
        };

        this.data = pairList;

      },
      error: (err: any) => {
        if ([400, 404].includes(err.status)) {
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  fetchDetailsWS(result: any) {
    console.log(result, '---------260')
    let pairList = []
    console.log(result.data, '------42')
    if (this.pairData._id.toString() == result.pairId.toString() && result.data.length == 0) {
      this.positionDetails.setPosition({})
      this.isPosition = false

    }
    for (let [index, item] of result.data.entries()) {
      console.log(item, '--------273')
      let avgPrice = item.avgPrice / item.quantity;
      let priceDecimal = item.priceDecimal;
      let finalQty: any = calPrice(item.filled, "orgquantity");
      finalQty = toFixedDown(finalQty, item?.quantityDecimal);

      console.log(finalQty, "------finalQty", this.pairData);
      let investmentPrice: any = calPrice(item.filled, "Totalprice");

      if (this.pairData._id.toString() == item.pairId.toString()) {
        this.positionDetails.setPosition(item)
        this.getBotHistoryCall(item)
        this.isPosition = true
      }


      let singlePair = this.pairs.find(
        (el: any) => el._id.toString() == item.pairId.toString()
      );
      console.log(singlePair, "-----singlePairsinglePair");
      let exovaluewfee;
      exovaluewfee = singlePair.markPrice * finalQty;
      // exovaluewfee = exovaluewfee - combuybnbUsdt;
      // exovaluewfee = exovaluewfee - (exovaluewfee * item.takerCommission)
      exovaluewfee = exovaluewfee
      let finalProfit = exovaluewfee - investmentPrice;
      // finalProfit = finalProfit - (finalProfit * item.takerCommission)
      finalProfit = toFixedDown(finalProfit, priceDecimal);
      let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

      let investment = 0;
      for (let data of item.entryPrice) {
        investment = investment + data.orgquantity * data.price;
      }

      let cmp1 = singlePair.markPrice * item.quantity;


      // console.log(investment, "investment----");
      // console.log(finalProfit, "finalProfit----");
      let curVal = investment + finalProfit;
      // console.log(curVal, "-------641");
      let txnDate = dateHours(item.createdAt)
      pairList.push({
        ...item,
        currentvalue: toFixedDown(curVal, item?.quantityDecimal),
        cmp: singlePair.markPrice,
        cmp1: cmp1,
        // investment: investment ? toFixed(investment, 4) : 0.0,
        investment: investment ? toFixedDown(investment, item?.quantityDecimal) : 0.0,
        pl: finalProfitPercentage ? toFixedDown(finalProfitPercentage, 4) : 0.0,
        pnlval: toFixedDown(finalProfit, 4),
        txnDate: txnDate,
        // profitinprice: profitinprice,
        referralCode: item.referralCode,
        takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
        index: index,
      });
    }
    const sumOfFields = pairList.reduce((sum, currentField) => {
      return sum + parseFloat(currentField.currentvalue);
    }, 0);

    const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
      return sum + parseFloat(currentField.pl);
    }, 0);

    const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
      return sum + parseFloat(currentField.pnlval);
    }, 0);

    this.totCurrent = sumOfFields;
    this.sumProfit = {
      sumpnl: sumOfFields_pnl,
      sumpnlval: sumOfFields_pnl_val,
    };
    console.log(pairList, '---------356')
    this.data = pairList;
  }
  getBotHistoryCall(reqData: any) {
    try {
      this.service.getBotHistory({ botId: reqData._id, }).subscribe({
        next: (v: any) => {
          let
            exitpricetot = 0,
            finalQty = 0,
            investmentPrice = 0;
          let index = 0;
          let result = v?.result
          let pairData = v?.pairData;

          if (!isEmpty(result) && result.length > 0) {
            for (let item of result) {
              let commission = 0,
                commissionUSDT = 0,
                commissionAsset = "";

              if (!isEmpty(item?.feeFilled)) {
                for (let feeitem of item?.feeFilled) {
                  commissionAsset = feeitem?.commissionAsset;
                  commission += parseFloat(feeitem?.commission);
                  commissionUSDT += parseFloat(feeitem?.commissionUSDT);
                }
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              } else {
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              }

              result[index].comissionFee = parseFloat(item.comissionFee);

              if (item.buyorsell == "buy") {
                investmentPrice += item.price * item.orgquantity;
                result[index].fee = toFixedDown(parseFloat(item.orgquantity) * result[0].takerCommission, 8) + " " + pairData[0].firstCurrencySymbol;
                result[index].feeUsdt = toFixedDown((item.price * item.orgquantity) * result[0].takerCommission, 8);

              }

              if (item.buyorsell == "sell") {
                exitpricetot += item.price * item.quantity;
                let ordVal = item.price * item.quantity;
                result[index].fee = toFixedDown(ordVal * result[0].takerCommission, 8) + " " + pairData[0].secondCurrencySymbol;
                result[index].feeUsdt = toFixedDown(ordVal * result[0].takerCommission, 8);


              }



              index++;
            }
            let quantityDecimal = result[0]?.quantityDecimal;
            finalQty = toFixedDown(finalQty, quantityDecimal);
            let priceDecimal = result[0].priceDecimal;

            if (exitpricetot > 0) {
              let exitovaluepop = 0;
              exitovaluepop =
                exitpricetot - exitpricetot * result[0].takerCommission;
              let profitall = exitovaluepop - investmentPrice;
              let profitpercentage = (profitall / investmentPrice) * 100;
              profitall = toFixedDown(profitall, priceDecimal);

              result[0].profitall = profitall;
              // result[0].profit = profit;
              result[0].profitpercentage = profitpercentage;
            }
            this.botData = result
          }
          this.botLOrders = v?.limitorders
        },
        error: (err: any) => {
          console.log(err, '-------76')
        },
        complete: () => { },
      });
    } catch (err) {
      console.log(err, "---81");
    }
  };
  pairMarkPrice(result: any) {
    let curPair: any = []
    this.data.forEach((item: any) => {
      if (item.pairId == result.pairId) {
        let combuybnbUsdt = 0;
        let finalQty = calPrice(item.filled, 'orgquantity');
        finalQty = toFixedDown(finalQty, item?.quantityDecimal);
        let investmentPrice = calPrice(item.filled, 'Totalprice');

        let exitOrdervalue = result.data.markPrice * finalQty;
        // exitOrdervalue = exitOrdervalue - (exitOrdervalue * item.takerCommission)
        exitOrdervalue = exitOrdervalue;
        let finalProfit: any = exitOrdervalue - investmentPrice;
        // finalProfit = finalProfit - (finalProfit * item.takerCommission)
        finalProfit = toFixedDown(finalProfit, 6);
        let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
        console.log(finalQty, 'hissoc1');
        console.log(exitOrdervalue, 'hissoc3');
        console.log(result.data.markPrice, 'hissoc4');
        console.log(finalProfit, 'hissoc5');
        console.log(investmentPrice, 'hissoc6');
        console.log(combuybnbUsdt, 'hissoc7');
        console.log(finalProfitPercentage, 'hissoc799');

        let investment = 0;
        for (let data of item.entryPrice) {
          investment += data.orgquantity * data.price;
        }

        let curVal = investmentPrice + parseFloat(finalProfit);
        console.log(curVal, '------352')
        curPair.push({
          ...item,
          cmp: result.data.markPrice,
          pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
          pnlval: finalProfit,
          currentvalue: toFixedDown(curVal, item?.quantityDecimal),
          investment: toFixed(investment, 4),
          StrategyPeriod: item.tradeSetting.StrategyPeriod,
          referralCode: item.referralCode,
        });
      } else {
        curPair.push(item);
      }
    });
    const sumOfFieldsPnl = curPair.reduce((sum: any, currentField: any) => {
      return sum + parseFloat(currentField.pl);
    }, 0);

    const sumOfFieldsPnlVal = curPair.reduce((sum: any, currentField: any) => {
      return sum + parseFloat(currentField.pnlval);
    }, 0);

    this.totCurrent = curPair.reduce((sum: any, currentField: any) => {
      return sum + parseFloat(currentField.currentvalue);
    }, 0);;
    this.sumProfit = {
      sumpnl: sumOfFieldsPnl,
      sumpnlval: sumOfFieldsPnlVal,
    };
    this.data = curPair;
  }

  fetchOrderHistory(filter: any) {
    console.log(filter, "Gellll")
    let reqData = {
      botType: "GENIE",
      pair: `${this.pairData?.firstCurrencySymbol}${this.pairData?.secondCurrencySymbol}`,
      exchange: this.pairData?.botstatus,
      ...filter,
      // sDate,
      // lDate,
    };
    this.service.getTradeHistory(reqData).subscribe({
      next: (v: any) => {
        let result = v?.result
        let count = v?.count
        let index = 0;
        let sumProfit = 0,
          totcurVal = 0,
          sumProPer = 0,
          totinv = 0;

        for (let [rIndex, history] of result.entries()) {
          let filledArr = history?.filled;
          let finalProfitPercentage: any,
            finalProfit,
            closeDate,
            exitpricetot = 0,
            investmentPrice = 0,
            feeUsdt = 0,
            comsellbnbusdt = 0,
            exitovalue = 0,
            totbuyqty = 0;

          for (let item of filledArr) {
            if (item.buyorsell == "buy") {
              investmentPrice += item.price * item.orgquantity;
              feeUsdt += (item.price * item.orgquantity) * history.takerCommission;
              totbuyqty += item.orgquantity;

            }

            if (item.buyorsell == "sell") {
              console.log(item.quantity, "238");
              exitpricetot += item.price * totbuyqty;
              closeDate = item.createdAt;
              feeUsdt += (item.price * item.quantity) * history.takerCommission;

            }
          }

          if (exitpricetot > 0) {
            if (comsellbnbusdt == 0) {
              // exitovalue =
              //   exitpricetot - exitpricetot * result[0].takerCommission;
              exitovalue = exitpricetot
              console.log(exitovalue, "exitovalueexitovalueexitovalue")
            } else {
              exitovalue = exitpricetot - comsellbnbusdt;
            }

            finalProfit = exitovalue - investmentPrice; // exitovalue - combuybnbUsdt - investmentPrice;

            finalProfitPercentage = (finalProfit / investmentPrice) * 100;

            finalProfit = toFixedDown(finalProfit, history.priceDecimal);
          }

          let curVal =
            history.status != "Binance Cancelled"
              ? investmentPrice + parseFloat(finalProfit)
              : 0;
          result[index].currentvalue = toFixedDown(curVal, 4);
          result[index].pl =
            history.status != "Binance Cancelled" ? toFixedDown(finalProfitPercentage, 2) : 0;
          result[index].pnlval =
            history.status != "Binance Cancelled" ? toFixedDown(finalProfit, 2) : 0;
          result[index].investment =
            history.status != "Binance Cancelled" ? toFixed(investmentPrice, 4) : 0;
          result[index].closeDate = dateHourswithTime(closeDate);
          result[index].creAt = dateHoursClose(history.createdAt, closeDate);
          result[index].secondCurrency = history.secondCurrency;
          result[index].index = rIndex;
          result[index].feeUsdt = feeUsdt;

          sumProfit += parseFloat(
            history.status != "Binance Cancelled" ? finalProfit : 0
          );
          sumProPer += parseFloat(finalProfitPercentage);
          totcurVal += curVal;
          totinv += investmentPrice;
          index++;
        }

        this.history = result;


        this.filter.totalPages = Math.ceil(count / this.filter.limit)
        this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5) 


        this.sumProfitHis = {
          sumpnl: toFixed(sumProPer, 2),
          sumpnlval: toFixed(sumProfit, 2),
          curVal: toFixed(totcurVal, 2),
          inv: toFixed(totinv, 2),
          pnlVal: toFixed(
            (sumProfit / totinv) *
            100,
            2
          )
        };
      },
      error: (err: any) => {

      },
      complete: () => { },
    });
  }
  fetchOHWS(response: any) {

    console.log(response, 'respData')
    let respData = response.data;
    let count = response.count;
    let index = 0;
    let sumProfit = 0,
      totcurVal = 0,
      totinv = 0,
      sumprofitpercentage = 0;
    for (let [rIndex, history] of respData.entries()) {
      console.log("historyhistory", history)
      let filledArr = history?.filled;
      let finalProfitPercentage: any,
        finalProfit,
        closeDate,
        entrypricetot = 0,
        exitpricetot = 0,
        investmentPrice = 0,
        feeUsdt = 0,
        totbuyqty = 0;

      for (let item of filledArr) {
        if (item.buyorsell == "buy") {
          entrypricetot += item.price * item.orgquantity;
          investmentPrice += item.price * item.orgquantity;

          feeUsdt += (item.price * item.orgquantity) * history.takerCommission;
          totbuyqty += item.orgquantity;

        }

        if (item.buyorsell == "sell") {
          // exitpricetot += item.price * item.quantity;
          exitpricetot += item.price * totbuyqty;
          closeDate = item.createdAt;
          feeUsdt += (item.price * item.quantity) * history.takerCommission;
          closeDate = item.createdAt;
        }
      }
      let exitovalue = 0;

      if (exitpricetot > 0) {
        exitovalue = exitpricetot
        finalProfit = exitovalue - investmentPrice; //  exitovalue - combuybnbUsdt - investmentPrice;
        finalProfitPercentage = (finalProfit / investmentPrice) * 100;
        finalProfit = toFixedDown(finalProfit, history.priceDecimal);
      }

      let curVal =
        history.status != "Binance Cancelled"
          ? investmentPrice + parseFloat(finalProfit)
          : 0;
      respData[index].currentvalue = toFixedDown(curVal, 4);
      respData[index].pl =
        history.status != "Binance Cancelled" ? toFixedDown(finalProfitPercentage, 2) : 0;
      respData[index].pnlval =
        history.status != "Binance Cancelled" ? toFixedDown(finalProfit, 2) : 0;
      respData[index].investment =
        history.status != "Binance Cancelled" ? toFixed(investmentPrice, 4) : 0;
      respData[index].closeDate = dateHourswithTime(closeDate);
      respData[index].creAt = dateHoursClose(history.createdAt, closeDate);
      respData[index].secondCurrency = history.secondCurrency;
      respData[index].index = rIndex;
      respData[index].feeUsdt = feeUsdt;

      sumProfit += parseFloat(
        history.status != "Binance Cancelled" ? finalProfit : 0
      );
      sumprofitpercentage += parseFloat(finalProfitPercentage);
      totcurVal += curVal;
      totinv += investmentPrice;
      index++;
    }
    console.log(respData, "75757577575")
    this.history = respData;

    this.filter.totalPages = Math.ceil(count / this.filter.limit)
    this.filter.page = 1;
    this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5)


    this.sumProfitHis = {
      sumpnl: toFixed(sumprofitpercentage, 2),
      sumpnlval: toFixed(sumProfit, 2),
      curVal: toFixed(totcurVal, 2),
      inv: toFixed(totinv, 2),
      pnlVal: toFixed(
        (sumProfit / totinv) *
        100,
        2
      )
    };
  }

  getBotHistoryCallpop(reqData: any) {
    try {
      this.service.getBotHistory({ botId: reqData._id, }).subscribe({
        next: (v: any) => {
          let
            exitpricetot = 0,
            finalQty = 0,
            investmentPrice = 0;

          let index = 0;
          let result = v?.result
          let pairData = v?.pairData;

          if (!isEmpty(result) && result.length > 0) {
            for (let item of result) {
              let commission = 0,
                commissionUSDT = 0,
                commissionAsset = "";

              if (!isEmpty(item?.feeFilled)) {
                for (let feeitem of item?.feeFilled) {
                  commissionAsset = feeitem?.commissionAsset;
                  commission += parseFloat(feeitem?.commission);
                  commissionUSDT += parseFloat(feeitem?.commissionUSDT);
                }
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              } else {
                result[index].commissionAsset = commissionAsset;
                result[index].commission = commission;
                result[index].commissionUSDT = commissionUSDT;
              }

              result[index].comissionFee = parseFloat(item.comissionFee);

              if (item.buyorsell == "buy") {
                investmentPrice += item.price * item.orgquantity;
                result[index].fee = toFixedDown(parseFloat(item.orgquantity) * result[0].takerCommission, 8) + " " + pairData[0].firstCurrencySymbol;
                result[index].feeUsdt = toFixedDown((item.price * item.orgquantity) * result[0].takerCommission, 8);

              }

              if (item.buyorsell == "sell") {
                exitpricetot += item.price * item.quantity;
                let ordVal = item.price * item.quantity;
                result[index].fee = toFixedDown(ordVal * result[0].takerCommission, 8) + " " + pairData[0].secondCurrencySymbol;
                result[index].feeUsdt = toFixedDown(ordVal * result[0].takerCommission, 8);
              }
              index++;
            }
            let quantityDecimal = result[0]?.quantityDecimal;
            finalQty = toFixedDown(finalQty, quantityDecimal);
            let priceDecimal = result[0].priceDecimal;
            //let priceDecimal = 6;

            if (exitpricetot > 0) {
              let exitovaluepop = 0;
              exitovaluepop =
                exitpricetot - exitpricetot * result[0].takerCommission;
              let profitall = exitovaluepop - investmentPrice;
              let profitpercentage = (profitall / investmentPrice) * 100;
              profitall = toFixedDown(profitall, priceDecimal);

              result[0].profitall = profitall;
              // result[0].profit = profit;
              result[0].profitpercentage = profitpercentage;
            }
            this.botDatapop = result
          }

        },
        error: (err: any) => {
          console.log(err, '-------76')
        },
        complete: () => { },
      });
    } catch (err) {
      console.log(err, "---81");
    }
  };


  // Pagination

  nextPage() {
    this.filter.page += 1

    this.fetchOrderHistory(this.filter);
  }

  goToPage(pageNo: number) {
    this.filter.page = pageNo
    this.fetchOrderHistory(this.filter);
  }

  previousPage() {
    if (this.filter.page > 1)
      this.filter.page -= 1

    this.fetchOrderHistory(this.filter);
  }
}