<div class="bot-page-main">
  <app-header-bot></app-header-bot>
  <div class="bot-container">
    <div class="bot-box-left">
      <div class="botChart"></div>
      <div class="bot-history">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="strategies-tab-pane"
            role="tabpanel"
            aria-labelledby="strategies-tab"
            tabindex="0"
          >
            <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="spot-bot-tab"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#spot-bot"
                  role="tab"
                  aria-controls="spot-bot"
                  aria-selected="true"
                >
                  Spot Bots (0)
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="dca-bot-history-tab"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#dca-bot-history"
                  role="tab"
                  aria-controls="dca-bot-history"
                  aria-selected="false"
                >
                  Bot History
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="spot-bot"
                role="tabpanel"
                aria-labelledby="spot-bot-tab"
              >
                <div class="bot-history-filter">
                  <p>Sum, Current value $ 40.04</p>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Exchange</th>
                        <th>Pair/Bot Type</th>
                        <th>Current Value</th>
                        <th>Total PNL</th>
                        <th>TXNS/Time</th>
                        <th>Status</th>
                        <th>Close</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 10m 19h 54mins</td>
                        <td class="red_txt">Close</td>
                        <td>
                          <img
                            src="../../../assets/images/notallow.png"
                            alt="notallow"
                            title="notallow"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 10m 19h 54mins</td>
                        <td class="green_txt">open</td>
                        <td>
                          <img
                            src="../../../assets/images/notallow.png"
                            alt="notallow"
                            title="notallow"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 10m 19h 54mins</td>
                        <td class="red_txt">Close</td>
                        <td>
                          <img
                            src="../../../assets/images/notallow.png"
                            alt="notallow"
                            title="notallow"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 10m 19h 54mins</td>
                        <td class="red_txt">Close</td>
                        <td>
                          <img
                            src="../../../assets/images/notallow.png"
                            alt="notallow"
                            title="notallow"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 10m 19h 54mins</td>
                        <td class="red_txt">Close</td>
                        <td>
                          <img
                            src="../../../assets/images/notallow.png"
                            alt="notallow"
                            title="notallow"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="dca-bot-history"
                role="tabpanel"
                aria-labelledby="dca-bot-history-tab"
              >
                <!-- <div class="noActiveBots">
                    <img src="assets/images/noactivebots.svg" class="img-fluid" alt="icon" />
                    <p>You don’t have active bots</p>
                    <button class="btn startButton">Start NewBot</button>
                  </div> -->
                <div class="bot-history-filter">
                  <div class="bh-filter-left">
                    <p>Sum, Current value $ 40.04</p>
                    <p>
                      Sum, bot profit $ -4.34
                      <span class="redText">-0.45%</span>
                    </p>
                    <p>Sum, Investment $ 966.38</p>
                  </div>
                  <div class="bh-filter-right">
                    <mat-form-field appearance="fill" class="date-picker">
                      <input matInput [matDatepicker]="picker" />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <button class="continue">
                      <img
                        src="../../../assets/images/date_picker.png"
                        alt="date"
                        title="date"
                      />
                    </button>
                    <button class="startButton">Download CSV</button>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Exchange</th>
                        <th>Pair/Bot Type</th>
                        <th>Current Value</th>
                        <th>PNL (With Fee)</th>
                        <th>TXNS/Time</th>
                        <th>Inverstment</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 16mins</td>
                        <td>22.1868</td>
                        <td>
                          <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 16mins</td>
                        <td>22.1868</td>
                        <td>
                          <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 16mins</td>
                        <td>22.1868</td>
                        <td>
                          <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 16mins</td>
                        <td>22.1868</td>
                        <td>
                          <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="assets/images/binance.svg"
                            alt="Binance"
                            title="Binance"
                          />
                          Binance
                        </td>
                        <td>
                          ETH/USDT <span class="botType">DCA</span>
                          <span class="botAction">Long</span>
                        </td>
                        <td>40.0368</td>
                        <td>
                          80.46% <span class="valueInPrice">17.85 USDT</span>
                        </td>
                        <td>3 / 16mins</td>
                        <td>22.1868</td>
                        <td>
                          <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot-container-2">
    <div class="bot-box-midd">
      <app-gridbotcreate></app-gridbotcreate>
      <!-- <app-gridbotpreview></app-gridbotpreview> -->
      <!-- <app-gridbotdetails></app-gridbotdetails> -->
      <!-- <app-gridbotstop></app-gridbotstop> -->
    </div>
    <div class="bot-box-right">
      <app-gridbotpair></app-gridbotpair>
    </div>
  </div>
  </div>
</div>
<div
  class="modal fade primary_modal"
  id="filter_bot_modal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="new_bot_modalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">DCA Filter</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="filter_panel">
          <div class="filter_panel_row">
            <label>By exchanges</label>
            <div class="filter_btn_grp">
              <button class="selected">Binance</button>
              <button>Bybit</button>
            </div>
          </div>
        </div>
        <div class="filter_panel_btn">
          <button class="continue border_btn">Clear filter</button>
          <button class="continue">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade primary_modal detail_model"
  id="table_bot_modal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="new_bot_tableLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Bot Orders</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="bot-history">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="strategies-tab-pane"
              role="tabpanel"
              aria-labelledby="strategies-tab"
              tabindex="0"
            >
              <div class="d-flex justify-content-between">
                <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="spot-bot-detail-tab"
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#spot-bot-detail"
                      role="tab"
                      aria-controls="spot-bot-detail"
                      aria-selected="true"
                    >
                      History
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="dca-bot-details-history-tab"
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#dca-bot-details-history"
                      role="tab"
                      aria-controls="dca-bot-details-history"
                      aria-selected="false"
                    >
                      Open orders
                    </button>
                  </li>
                </ul>
                <div class="bot-history-filter">
                  <p>Bot profit $-0.00 <span class="red_txt">-0.02%</span></p>
                </div>
              </div>
              <div class="tab-content mt-3" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="spot-bot-detail"
                  role="tabpanel"
                  aria-labelledby="spot-bot-detail-tab"
                >
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Ex.</th>

                          <th>Date & Time Time</th>
                          <th>Side</th>
                          <th>Amount</th>
                          <th>Price</th>
                          <th>Action</th>
                          <th>Total (USDT)</th>
                          <th>Fee</th>
                          <th>Profit (USDT)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="assets/images/binance.svg"
                              class="img-fluid"
                              alt="icon"
                            />
                          </td>
                          <td>2024-06-19 17:55</td>
                          <td><span class="green_txt">Buy</span></td>
                          <td>2.540000 LDO</td>
                          <td>2.36</td>
                          <td>Initial</td>
                          <td>5.994 USDT</td>
                          <td>0.00000753 BNB 0.00457598 USDT</td>
                          <td>-0.07% O USDT</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Page navigation example botpagination">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#"><<</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">>></a>
                      </li>
                    </ul>
                  </nav>
                  <div class="bh-filter-right">
                    <button class="startButton mt-3">Download CSV</button>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="dca-bot-details-history"
                  role="tabpanel"
                  aria-labelledby="dca-bot-details-history-tab"
                >
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Ex.</th>
                          <th>Pair/Bot Type</th>
                          <th>Date & Time Time</th>
                          <th>Side</th>
                          <th>Action</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="assets/images/binance.svg"
                              class="img-fluid"
                              alt="icon"
                            />
                          </td>
                          <td>ETH/USDT <span class="botType">Grid</span></td>
                          <td>2024-05-23</td>
                          <td><span class="red_txt">Sell</span></td>
                          <td>Initial</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>Open</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src="assets/images/binance.svg"
                              class="img-fluid"
                              alt="icon"
                            />
                          </td>
                          <td>ETH/USDT <span class="botType">Grid</span></td>
                          <td>2024-05-23</td>
                          <td><span class="red_txt">Sell</span></td>
                          <td>Initial</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>Open</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src="assets/images/binance.svg"
                              class="img-fluid"
                              alt="icon"
                            />
                          </td>
                          <td>ETH/USDT <span class="botType">Grid</span></td>
                          <td>2024-05-23</td>
                          <td><span class="green_txt">buy</span></td>
                          <td>Initial</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>Open</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Page navigation example botpagination">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#"><<</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">>></a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade primary_modal detail_model"
  id="modify_bot_modal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="new_bot_modifyLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Modify Bot</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="createbot_box">
          <div class="boxFlexText mb-3">
            <p _ngcontent-ng-c446327758="" class="bot_preview_title mb-0">
              <i
                _ngcontent-ng-c446327758=""
                class="bi bi-info-circle-fill ms-2"
              ></i>
              Stop Loss
            </p>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                [(ngModel)]="isChecked"
              />
            </div>
          </div>
          <div *ngIf="isChecked">
            <div class="inputgrp_box">
              <div class="w-100 input-group">
                <label class="grey"> price</label>
                <input
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="investmentAmount"
                  class="form-control"
                  value="1.759"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="createbot_box">
          <div class="boxFlexText mb-3">
            <p _ngcontent-ng-c446327758="" class="bot_preview_title mb-0">
              <i
                _ngcontent-ng-c446327758=""
                class="bi bi-info-circle-fill ms-2"
              ></i>
              Take Profit
            </p>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                [(ngModel)]="isChecked2"
              />
            </div>
          </div>
          <div *ngIf="isChecked2">
            <div class="inputgrp_box">
              <div class="w-100 input-group">
                <label class="grey"> Total PNl,5% </label>
                <input
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="investmentAmount"
                  class="form-control"
                  value="5"
                />
              </div>
            </div>
          </div>
        </div>
        <button class="continue">Confirm</button>
      </div>
    </div>
  </div>
</div>
