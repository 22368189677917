import isEmpty from "./isEmpty";

export const dateHours = (dateTime: any) => {
    try {
        const startDate = new Date(dateTime);
        const endDate = new Date();
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
        const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(timeDiff / (1000 * 60 * 60));
        const diffMinutes = Math.floor(timeDiff / (1000 * 60));
        let formattedDuration: any = {};
        if (diffYears > 0) {
            formattedDuration.years = diffYears;
        }
        if (diffMonths >= 12) {
            formattedDuration.years = Math.floor(diffMonths / 12);
            formattedDuration.months = diffMonths % 12;
        } else {
            formattedDuration.months = diffMonths;
        }

        formattedDuration.days = diffDays % 30;
        formattedDuration.hours = diffHours % 24;
        formattedDuration.minutes = diffMinutes % 60;
        return formatDuration(formattedDuration);
    } catch (err) {
        return "";
    }
};

export const dateHoursClose = (dateTime: any,enddateTime:any) => {
    try {
        const startDate = new Date(dateTime);
        const endDate = new Date(enddateTime);
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
        const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(timeDiff / (1000 * 60 * 60));
        const diffMinutes = Math.floor(timeDiff / (1000 * 60));
        let formattedDuration: any = {};
        if (diffYears > 0) {
            formattedDuration.years = diffYears;
        }
        if (diffMonths >= 12) {
            formattedDuration.years = Math.floor(diffMonths / 12);
            formattedDuration.months = diffMonths % 12;
        } else {
            formattedDuration.months = diffMonths;
        }

        formattedDuration.days = diffDays % 30;
        formattedDuration.hours = diffHours % 24;
        formattedDuration.minutes = diffMinutes % 60;
        return formatDuration(formattedDuration);
    } catch (err) {
        return "";
    }
};

export const dateTimeFormat = (dateTime: any, format = "YYYY-MM-DD HH:mm") => {
    try {
        if (!isEmpty(dateTime)) {
            let newDateTime: any = new Date(dateTime);
            if (format.includes("YYYY")) {
                format = format.replace("YYYY", newDateTime.getFullYear());
            }

            if (format.includes("MM")) {
                let month = newDateTime.getMonth() + 1;
                month = month > 9 ? month : `0${month}`;
                format = format.replace("MM", month);
            }

            if (format.includes("DD")) {
                let date = newDateTime.getDate();
                date = date > 9 ? date : `0${date}`;
                format = format.replace("DD", date);
            }

            if (format.includes("HH")) {
                let hour = newDateTime.getHours();
                hour = hour > 9 ? hour : `0${hour}`;
                format = format.replace("HH", hour);
            }

            if (format.includes("mm")) {
                let minute = newDateTime.getMinutes();
                minute = minute > 9 ? minute : `0${minute}`;
                format = format.replace("mm", minute);
            }

            return format;
        } else {
            return "";
        }
    } catch (err) {
        return "";
    }
};
export const dateHourswithTime = (dateTime: any) => {
    try {
        const date = new Date(dateTime);

        const formattedDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
        }).format(date);

        return formattedDate;
    } catch (err) {
        return "";
    }
};
function formatDuration(duration: any) {
    let formattedString = "";

    if (duration.years) {
        formattedString += `${duration.years}y `;
    }

    if (duration.months) {
        formattedString += `${duration.months}m `;
    }

    if (duration.days) {
        formattedString += `${duration.days}d `;
    }

    if (duration.hours) {
        formattedString += `${duration.hours}h `;
    }

    if (duration.minutes >= 0) {
        formattedString += `${duration.minutes}min `;
    }

    return formattedString.trim();
}


export const dayOnly = (date1: any, date2: any) => {
    try {
        date1 = new Date(date1);
        date2 = new Date(date2);

        // Convert both dates to milliseconds since the Unix epoch
        const time1 = date1.getTime();
        const time2 = date2.getTime();

        // Calculate the difference in milliseconds
        const timeDiff = Math.abs(time2 - time1);

        // Convert the difference to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysDiff;
    } catch (err) {
        return "";
    }
};