<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="strategies-tab-pane" role="tabpanel" aria-labelledby="strategies-tab"
        tabindex="0">
        <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="spot-bot-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#spot-bot" role="tab" aria-controls="spot-bot" aria-selected="true">
                    Spot Bots ({{data.length}})
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="dca-bot-history-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#dca-bot-history" role="tab" aria-controls="dca-bot-history" aria-selected="false">
                    Bot History
                </button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="isPosition">
                <button class="nav-link" id="dca-ord-details-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#dca-ord-details" role="tab" aria-controls="dca-ord-details" aria-selected="false">
                    Order Details
                </button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="spot-bot" role="tabpanel" aria-labelledby="spot-bot-tab">
                <div class="bot-history-filter">
                    <p>Sum, Current value $ {{toFixed(totCurrent,2)}}</p>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Pair/Bot Type</th>
                                <th>Current Value</th>
                                <th>PNL</th>
                                <th>TXNS/Time</th>
                                <th>Investment</th>
                                <th>Take Profit</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="data.length > 0; else nodata">
                            <tr *ngFor="let item of data">
                                <td>
                                    {{item.pairName}}<span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>{{toFixedDown(item.currentvalue, item?.quantityDecimal)}}</td>
                                <!-- //redText -->
                                <td class="{{item.pl > 0 ? 'greenTxt' : 'redText'}}">
                                    {{toFixed(item.pl,2)}}% <span class="valueInPrice">{{item.pnlval}} USDT</span>
                                </td>
                                <td>{{item.txnDate}}</td>
                                <td>{{item.investment}}</td>
                                <td>{{item.takeProfit}}% USDT</td>
                            </tr>
                        </tbody>
                    </table>
                    <ng-template #nodata>
                        <tbody>
                            <tr>
                                <td colspan="9" style="border-color: transparent;">
                                    <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                                        <img src="assets/images/no_records_found.png" alt="Notification"
                                            class="img-fluid mb-2" />
                                        <p>No Records Found!</p>
                                    </div>
                                    <ng-template #login>
                                        <div class="text-center mt-4 mb-4">
                                            <!-- <p>
                                                <a class="blue_txt no-underline" href="signin">Login</a> or <a class="blue_txt"
                                                    href="signup" class="blue_txt no-underline">Register</a> Now to trade
                                            </p> -->
                                            <img src="assets/images/no_records_found.png" alt="Notification"
                                                class="img-fluid mb-2" />
                                            <p>
                                                <a class="blue_txt no-underline" href="signin">Login</a>
                                                or
                                                <a class="blue_txt" href="signup"
                                                    class="blue_txt no-underline">Register</a>
                                                Now to trade
                                            </p>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </div>
            </div>
            <div class="tab-pane fade" id="dca-bot-history" role="tabpanel" aria-labelledby="dca-bot-history-tab">
                <!-- <div class="noActiveBots">
            <img src="assets/images/noactivebots.svg" class="img-fluid" alt="icon" />
            <p>You don’t have active bots</p>
            <button class="btn startButton">Start NewBot</button>
          </div> -->
                <div class="bot-history-filter">
                    <!-- <div class="bh-filter-left">
                        <p>Sum, Current value $ {{toFixed(sumProfitHis?.curVal,2)}}</p>
                        <p>
                            Sum, bot profit $ {{sumProfitHis?.sumpnlval}}
                            <span *ngIf="sumProfitHis.sumpnlval < 0" class="redText">{{sumProfitHis?.pnlVal}}%</span>
                            <span *ngIf="sumProfitHis.sumpnlval > 0" class="greenText">{{sumProfitHis?.pnlVal}}%</span>
                        </p>
                        <p>Sum, Investment $ {{sumProfitHis?.inv}}</p>
                    </div> -->
                    <!-- <div class="bh-filter-right">
                        <mat-form-field appearance="fill">
                            <input matInput [matDatepicker]="picker" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <button class="continue">
                            <img src="../../../assets/images/date_picker.png" alt="date" title="date" />
                        </button>
                        <button class="startButton">Download CSV</button>
                    </div> -->
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <!-- <th>Exchange</th> -->
                                <th>Pair/Bot Type</th>
                                <th>Fee (USDT)</th>
                                <th>PNL</th>
                                <th>TXNS/Time</th>
                                <th>Investment</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="history.length > 0; else nohistorydata">
                            <tr *ngFor="let item of history">
                                <!-- <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td> -->
                                <td>
                                    {{item.pairName}} <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>{{toFixedDown(item.feeUsdt,6)}}</td>
                                <!-- //redText -->
                                <td class="{{parseFloat(item.pl)> 0 ? 'greenTxt' : 'redText'}}">
                                    {{item.pl}}% <span class="valueInPrice">{{item.pnlval}}
                                        {{item.secondCurrency}}</span>
                                </td>
                                <td>{{item.creAt}}</td>
                                <td>{{item.investment}}</td>
                                <td>
                                    <span class="greenTxt">Closed</span> {{item.closeDate}}
                                </td>
                                <td>

                                    <span class="botAction" (click)="getBotHistoryCallpop(item)" data-bs-toggle="modal"
                                        data-bs-target="#ohis_modal">View</span>

                                    <!-- <button   (click)="getBotHistoryCall(item)" class="deposit_btn btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#ohis_modal">
                                        View
                                    </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <nav class="primary_pagination" *ngIf="filter.totalPages > 1">
                        <ul class="pagination">
                          <li class="page-item" [ngClass]="{disabled: filter.page == 1}">
                            <span class="page-link cursor-pointer" aria-label="Previous" (click)="previousPage()">
                              <span aria-hidden="true">&laquo;</span>
                            </span>
                          </li>
                          <li class="page-item" *ngFor="let item of getDisplayedPages">
                            <a class="page-link" [ngClass]="{ active: item == filter.page}" href="javascript:void(0)"
                              (click)="goToPage(item)">{{item}}</a>
                          </li>
                          <li class="page-item" [ngClass]="{disabled: filter.totalPages == filter.page}">
                            <span class="page-link cursor-pointer" (click)="nextPage()" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                            </span>
                          </li>
                        </ul>
                      </nav>

                    <ng-template #nohistorydata>
                        <tbody>
                            <tr>
                                <td colspan="9" style="border-color: transparent;">
                                    <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                                        <img src="assets/images/no_records_found.png" alt="Notification"
                                            class="img-fluid mb-2" />
                                        <p>No Records Found!</p>
                                    </div>
                                    <ng-template #login>
                                        <div class="text-center mt-4 mb-4">
                                            <!-- <p>
                                                <a class="blue_txt no-underline" href="signin">Login</a> or <a class="blue_txt"
                                                    href="signup" class="blue_txt no-underline">Register</a> Now to trade
                                            </p> -->
                                            <img src="assets/images/no_records_found.png" alt="Notification"
                                                class="img-fluid mb-2" />
                                            <p>
                                                <a class="blue_txt no-underline" href="signin">Login</a>
                                                or
                                                <a class="blue_txt" href="signup"
                                                    class="blue_txt no-underline">Register</a>
                                                Now to trade
                                            </p>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="isPosition" class="tab-pane fade" id="dca-ord-details" role="tabpanel" aria-labelledby="dca-ord-details-tab">
                <div class="table-responsive">
                    <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
                        <li *ngIf="isPosition" class="nav-item" role="presentation">
                            <button class="nav-link active" id="spot-bot-detail-tab" type="button" data-bs-toggle="tab"
                                data-bs-target="#spot-bot-detail" role="tab" aria-controls="spot-bot-detail"
                                aria-selected="true">
                                History
                            </button>
                        </li>
                        <li *ngIf="isPosition" class="nav-item" role="presentation">
                            <button class="nav-link" id="dca-bot-details-history-tab" type="button" data-bs-toggle="tab"
                                data-bs-target="#dca-bot-details-history" role="tab"
                                aria-controls="dca-bot-details-history" aria-selected="false">
                                Open orders
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content mt-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="spot-bot-detail" role="tabpanel"
                            aria-labelledby="spot-bot-detail-tab">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date & Time Time</th>
                                            <th>Side</th>
                                            <th>Action</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Total (USDT)</th>
                                            <th>Fee</th>
                                            <th>Profit (USDT)</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="botData.length > 0; else noBotHistory">
                                        <tr *ngFor="let row of botData">
                                            <td>{{ dateTimeFormat(row.updatedAt) }}</td>
                                            <td *ngIf="row.buyorsell == 'sell'">
                                                <span class="red_txt">{{
                                                    firstCapital(row.buyorsell)
                                                    }}</span>
                                            </td>
                                            <td *ngIf="row.buyorsell == 'buy'">
                                                <span class="green_txt">{{
                                                    firstCapital(row.buyorsell)
                                                    }}</span>
                                            </td>
                                            <td>{{ row.orderVia }}</td>
                                            <td>
                                                {{ toFixedDown(row.price, row.priceDecimal) }}
                                            </td>
                                            <td>{{ row.orgquantity }}</td>
                                            <td>{{ toFixed(row.price * row.orgquantity, 4) }}</td>
                                            <td>{{ row.fee}}</td>
                                         
                                            <td *ngIf="
                                      row.profitpercentage >= 0 &&
                                      row.buyorsell === 'sell'
                                    " class="green_txt">
                                                {{ toFixedDown(row.profitpercentage, 2) }}%
                                                {{ toFixedDown(row.profitall, 2) }}
                                                {{ pairData.secondCurrencySymbol }}
                                            </td>
                                            <td *ngIf="
                                      row.profitpercentage <= 0 &&
                                      row.buyorsell === 'sell'
                                    " class="red_txt">
                                                {{ toFixedDown(row.profitpercentage, 2) }}%
                                                {{ toFixedDown(row.profitall, 2) }}
                                                {{ pairData.secondCurrencySymbol }}
                                            </td>
                                            <td *ngIf="row.buyorsell === 'buy'">-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ng-template #noBotHistory>
                                    <tbody>
                                        <tr>
                                            <td colspan="9" style="border-color: transparent;">
                                                <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                                                    <img src="assets/images/no_records_found.png" alt="Notification"
                                                        class="img-fluid mb-2" />
                                                    <p>No Records Found!</p>
                                                </div>
                                                <ng-template #login>
                                                    <div class="text-center mt-4 mb-4">
                                                        <!-- <p>
                                                            <a class="blue_txt no-underline" href="signin">Login</a> or <a class="blue_txt"
                                                                href="signup" class="blue_txt no-underline">Register</a> Now to trade
                                                        </p> -->
                                                        <img src="assets/images/no_records_found.png" alt="Notification"
                                                            class="img-fluid mb-2" />
                                                        <p>
                                                            <a class="blue_txt no-underline" href="signin">Login</a>
                                                            or
                                                            <a class="blue_txt" href="signup"
                                                                class="blue_txt no-underline">Register</a>
                                                            Now to trade
                                                        </p>
                                                    </div>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </ng-template>
                            </div>
                            <!-- <div class="bh-filter-right">
                            <button class="startButton mt-3">Download CSV</button>
                          </div> -->
                        </div>
                        <div class="tab-pane fade" id="dca-bot-details-history" role="tabpanel"
                            aria-labelledby="dca-bot-details-history-tab">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date & Time Time</th>
                                            <th>Side</th>
                                            <th>Action</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Total ({{ pairData.secondCurrencySymbol }})</th>
                                            <th>Order Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="botLOrders.length > 0; else noOpenData">
                                        <tr *ngFor="let row of botLOrders">
                                            <td>{{ dateTimeFormat(row.updatedAt) }}</td>
                                            <td *ngIf="row.buyorsell === 'buy'">
                                                <span class="green_txt">{{
                                                    firstCapital(row.buyorsell)
                                                    }}</span>
                                            </td>
                                            <td *ngIf="row.buyorsell === 'sell'">
                                                <span class="red_txt">{{
                                                    firstCapital(row.buyorsell)
                                                    }}</span>
                                            </td>
                                            <td>{{ row.orderVia }}</td>
                                            <td>{{ row.price }}</td>
                                            <td>
                                                {{ row.orgquantity }}
                                            </td>
                                            <td>{{ toFixed(row.price * row.orgquantity, 4) }}</td>
                                            <td *ngIf="row.orderStatus == 'Cancelled'">
                                                Manual Cancelled from exchange
                                            </td>
                                            <td *ngIf="row.orderStatus != 'Cancelled'">
                                                {{ row.orderStatus }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


         
            <ng-template #noOpenData>
                <tbody>
                    <tr>
                        <td colspan="9" style="border-color: transparent;">
                            <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                                <img src="assets/images/no_records_found.png" alt="Notification"
                                    class="img-fluid mb-2" />
                                <p>No Records Found!</p>
                            </div>
                            <ng-template #login>
                                <div class="text-center mt-4 mb-4">
                                    <!-- <p>
                                        <a class="blue_txt no-underline" href="signin">Login</a> or <a class="blue_txt"
                                            href="signup" class="blue_txt no-underline">Register</a> Now to trade
                                    </p> -->
                                    <img src="assets/images/no_records_found.png" alt="Notification"
                                        class="img-fluid mb-2" />
                                    <p>
                                        <a class="blue_txt no-underline" href="signin">Login</a>
                                        or
                                        <a class="blue_txt" href="signup"
                                            class="blue_txt no-underline">Register</a>
                                        Now to trade
                                    </p>
                                </div>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </div>

           <!-- Order history Modal -->
           <div class="modal fade primary_modal detail_model modal-xl" id="ohis_modal" tabindex="-1"
           aria-labelledby="ohis_modalLabel" aria-hidden="true">
           <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                   <div class="modal-header">
                       <h1 class="modal-title fs-5" id="ohis_modalLabel">
                           Order History
                       </h1>
                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   </div>

                   <div class="modal-body">

                       <div class="table-responsive">
                           <table class="table table-bordered">
                               <thead>
                                   <tr>
                                       <th>Date & Time</th>
                                       <th>Side</th>
                                       <th>Action</th>
                                       <th>Price</th>
                                       <th>Amount</th>
                                       <th>Total ({{ pairData.secondCurrencySymbol }})</th>
                                       <th>Fee</th>
                                       <th>Fee (USDT)</th>
                                       <th>PNL</th>
                                   </tr>
                               </thead>
                               <tbody *ngIf="botDatapop.length > 0; else noOpenData">
                                   <tr *ngFor="let row of botDatapop">
                                       <td>{{ dateTimeFormat(row.updatedAt) }}</td>
                                       <td *ngIf="row.buyorsell === 'buy'">
                                           <span class="green_txt">{{
                                               firstCapital(row.buyorsell)
                                               }}</span>
                                       </td>
                                       <td *ngIf="row.buyorsell === 'sell'">
                                           <span class="red_txt">{{
                                               firstCapital(row.buyorsell)
                                               }}</span>
                                       </td>
                                       <td>{{ row.orderVia }}</td>
                                       <td>{{ row.price }}</td>
                                       <td>
                                           {{ row.orgquantity }}
                                       </td>
                                       <td>{{ toFixed(row.price * row.orgquantity, 4) }}</td>
                                       <td>
                                        {{ row.fee }}
                                    </td>

                                    <td>{{ row.feeUsdt }}</td>

                                    <td *ngIf="row.buyorsell === 'sell'">
                                        {{ row.profitall }}
                                    </td>

                                    

                                   </tr>
                               </tbody>
                           </table>

                       </div>

                   </div>
               </div>
           </div>
       </div>


    </div>
</div>