<header class="header">
    <div class="container-fluid">
        <div class="header__content">
            <nav class="navbar bg-body-dark navbar-expand-xl">
                <div class="d-flex align-items-center">
                    <a class="navbar-brand header__logo" href="/">
                        <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'"
                            alt="Logo" class="img-fluid" />
                    </a>
                    <ul class="navbar-nav mobile_none home_navbar_middle">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/market-home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Market</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fee-home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Fee</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/spot" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Trade</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/launchpad" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Launchpad</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" routerLink="/dcabot" routerLinkActive="active"
                              [routerLinkActiveOptions]="{ exact: true }">DCA</a>
                          </li> -->
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>
                        </li>
                    </ul>
                </div>
                <button class="header__btn navbar-toggler" type="button">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <app-theme class="dashboard_theme"></app-theme>
                <div class="home_menu_bar" tabindex="-1">
                    <ul class="navbar-nav justify-content-end flex-grow-1 desktop_none">
                        <li class="nav-item user_info_dropdown" *ngIf="loggedIn">
                            <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div class="sidebar__user-img">
                                    <span>
                                        {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                                        }}{{
                                        profileDetails.lastName | slice : 0 : 1 | uppercase
                                        }}</span>
                                </div>

                                <div class="sidebar__user-title">
                                    <p>
                                        {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/market-home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Market</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fee-home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Fee</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/spot" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Trade</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/signin" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Launchpad</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/edit-profile" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Edit Profile</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/wallet" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Wallet</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/subscriptionpage" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Subscription</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" routerLink="/security" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Security</a>
                        </li>
                        <li class="nav-item" *ngIf="loggedIn">
                            <a class="nav-link" href="javascript:void(0)" routerLinkActive="active" (click)="signOut()"
                                [routerLinkActiveOptions]="{ exact: true }">Logout</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li class="nav-item" *ngIf="!loggedIn">
                            <a class="nav-link" routerLink="/signin" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Sign In</a>
                        </li>
                        <li class="nav-item" *ngIf="!loggedIn">
                            <a class="nav-link primary_btn" routerLink="/signup" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Sign Up</a>
                        </li>
                        <li class="nav-item mobile_none" *ngIf="loggedIn">
                            <button class="btn startButton" data-bs-toggle="modal" data-bs-target="#new_bot_modal"><img
                                    [src]="theme == 'dark' ? 'assets/images/startboticon.svg': 'assets/images/startboticon.svg'"
                                    alt="Bot Status" class="img-fluid" /> Start New Bot</button>
                        </li>
                        <li class="nav-item dropdown activeBots_info_dropdown mobile_none" *ngIf="loggedIn">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <img [src]="theme == 'dark' ? 'assets/images/bostatusticon.svg': 'assets/images/bostatusticon.svg'"
                                    alt="Bot Status" class="img-fluid" /> {{dcaData.length}}
                            </a>
                            <div class="dropdown-menu  dropdown-menu-end botstatus_dropdown">
                                <h3>Active bots limit</h3>
                                <div class="bot_preview_box">
                                    <div>
                                        <span class="running_bots_count"><svg
                                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                                                focusable="false" aria-hidden="true" viewBox="0 0 32 32" width="32"
                                                height="32">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M22.6667 28C21.9333 28 21.3333 27.4 21.3333 26.6667L21.3333 5.33333C21.3333 4.6 21.9333 4 22.6667 4C23.4 4 24 4.6 24 5.33333L24 26.6667C24 27.4 23.4 28 22.6667 28ZM8 26.6667L8 21.3333C8 20.6 8.6 20 9.33333 20C10.0667 20 10.6667 20.6 10.6667 21.3333L10.6667 26.6667C10.6667 27.4 10.0667 28 9.33333 28C8.6 28 8 27.4 8 26.6667ZM14.6667 13.3333L14.6667 26.6667C14.6667 27.4 15.2667 28 16 28C16.7333 28 17.3333 27.4 17.3333 26.6667L17.3333 13.3333C17.3333 12.6 16.7333 12 16 12C15.2667 12 14.6667 12.6 14.6667 13.3333Z"
                                                    fill="#61C065"></path>
                                            </svg><span>DCA bots</span></span><span
                                            class="border_line"></span><span><span
                                                class="blueText">{{dcaData.length}}</span> </span>
                                    </div>
                                    <div><span class="running_bots_count"><svg
                                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                                                focusable="false" aria-hidden="true" viewBox="0 0 32 32" width="32"
                                                height="32">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M26.6667 5.33331H5.33333C4.6 5.33331 4 5.93331 4 6.66665V9.33331C4 10.0666 4.6 10.6666 5.33333 10.6666H26.6667C27.4 10.6666 28 10.0666 28 9.33331V6.66665C28 5.93331 27.4 5.33331 26.6667 5.33331ZM5.33333 13.3333H26.6667C27.4 13.3333 28 13.9333 28 14.6666V16C28 16.7333 27.4 17.3333 26.6667 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16V14.6666C4 13.9333 4.6 13.3333 5.33333 13.3333ZM5.33333 20H26.6667C27.4 20 28 20.6 28 21.3333C28 22.0666 27.4 22.6666 26.6667 22.6666H5.33333C4.6 22.6666 4 22.0666 4 21.3333C4 20.6 4.6 20 5.33333 20ZM4.66667 25.3333H27.3333C27.7067 25.3333 28 25.6266 28 26C28 26.3733 27.7067 26.6666 27.3333 26.6666H4.66667C4.29333 26.6666 4 26.3733 4 26C4 25.6266 4.29333 25.3333 4.66667 25.3333Z"
                                                    fill="#61C065"></path>
                                            </svg><span>GRID Bots</span></span><span
                                            class="border_line"></span><span><span class="blueText">0</span> </span>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item dropdown user_info_dropdown mobile_none" *ngIf="loggedIn">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <div class="sidebar__user-img">
                                    <span>
                                        {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                                        }}{{
                                        profileDetails.lastName | slice : 0 : 1 | uppercase
                                        }}</span>
                                </div>

                                <div class="sidebar__user-title">
                                    <p>
                                        {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                                    </p>
                                </div>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="dropdown-item" routerLink="/edit-profile"><i
                                            class="bi bi-pencil-square me-1"></i> Edit Profile</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" routerLink="/wallet"><i
                                            class="bi bi-wallet2 me-1"></i>Wallet</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" routerLink="/security"><i
                                            class="bi bi-shield-check me-1"></i>Security</a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="signOut()"><i
                                            class="bi bi-door-closed me-1"></i>Logout</a>
                                </li>
                            </ul>
                        </li>
                        <li class="mobile_none">
                            <app-theme></app-theme>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="modal fade primary_modal" id="new_bot_modal" data-bs-backdrop="static" tabindex="-1"
    aria-labelledby="new_bot_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="new_bot_modalLabel">
                    Start new bot
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="new_bot_panel">
                    <a href="javascript:void(0)">
                        <div class="new_bot_panel_content">
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                                focusable="false" aria-hidden="true" viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="36" height="36" rx="10" fill="#28BA74"></rect>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M24 28.8c-.66 0-1.2-.54-1.2-1.2V8.4c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v19.2c0 .66-.54 1.2-1.2 1.2Zm-13.2-1.2v-4.8c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v4.8c0 .66-.54 1.2-1.2 1.2-.66 0-1.2-.54-1.2-1.2Zm6-12v12c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2v-12c0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2Z"
                                    fill="#fff"></path>
                            </svg>
                            <h4>DCA Bot</h4>
                        </div>
                        <p>
                            The Dollar-cost Averaging bot multiplies your gains and
                            reduces risks in volatile markets.
                        </p>
                    </a>
                    <a href="javascript:void(0)">
                        <div class="new_bot_panel_content">
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                                focusable="false" aria-hidden="true" viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="36" height="36" rx="10" fill="#6552EC"></rect>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M27.6 8.1H8.4c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2h19.2c.66 0 1.2-.54 1.2-1.2V9.3c0-.66-.54-1.2-1.2-1.2ZM8.4 15.3h19.2c.66 0 1.2.54 1.2 1.2v1.2c0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2v-1.2c0-.66.54-1.2 1.2-1.2Zm0 6h19.2c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2Zm-.6 4.8h20.4c.336 0 .6.264.6.6 0 .336-.264.6-.6.6H7.8a.594.594 0 0 1-.6-.6c0-.336.264-.6.6-.6Z"
                                    fill="#fff"></path>
                            </svg>
                            <h4>GRID Bot</h4>
                        </div>
                        <p>
                            The proven grid strategy bot maximizes your returns on the
                            slightest price changes.
                        </p>
                    </a>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- end header -->