import { Component, Input } from '@angular/core';
import { convert, longDecimals, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';

// Import facade
import { MarketPriceFacade } from 'src/app/store/marketprice';
import { PriceCnvFacade } from 'src/app/store/pricecnv';
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-tickerprice',
  templateUrl: './tickerprice.component.html',
  styleUrls: ['./tickerprice.component.css']
})
export class TickerpriceComponent {
  @Input() tikerRoot: any

  toFixedDown = toFixedDown
  convert = convert
  truncateDecimals = truncateDecimals
  longDecimals = longDecimals

  tickerData: any = {}
  pairData: any = {}

  convertPrice: number = 1

  constructor(
    private marketPriceFacade: MarketPriceFacade,
    private tradePairFacade: TradePairFacade,
    private priceCnvFacade: PriceCnvFacade
  ) { }


  ngOnInit() {
    this.marketPriceFacade.marketPrice$.subscribe({
      next: (pair: any) => {
        this.tickerData = pair
        console.log(pair, "pair456")
      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
        this.usdtPrice(pair.secondCurrencySymbol)
      },
      error: (err: any) => { }
    })
  }

  // get usdt conversion price
  usdtPrice(secondCurrency: string) {
    if (secondCurrency === 'USDT') {
      this.convertPrice = 1
      return
    }
    this.priceCnvFacade.pricecnv$.subscribe({
      next: (response: any) => {
        if (response && response.length > 0) {
          const conversion = response.find(
            (item: any) => item.baseSymbol == secondCurrency && item.convertSymbol == 'USDT'
          )
          if (conversion)
            this.convertPrice = parseFloat(conversion.convertPrice)
        }
      },
      error: (error: any) => { 
        // console.log(error) 
      }
    })
  }

}
