import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BotService } from 'src/app/services/bot.service';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-dcabotstop',
  templateUrl: './dcabotstop.component.html',
  styleUrls: ['./dcabotstop.component.css']
})
export class DcabotstopComponent {
  @Input() posDetails: any
  @Input() closeType: any
  loader = false;
  isPrevious = false;
  pairData: any = {}
  constructor(
    private botFacade: TradePairFacade,
    private service: BotService,
    private toastr: ToastrService,
    private positionDetails: PositionDetailsFacade,
  ) { }
  ngOnInit() {
    this.getSinglePair()
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
      },
      error: (err: any) => { }
    })
  }
  goPrevious() {
    this.isPrevious = true
  }
  cancelOrder() {
    this.loader = true
    // try {
    let reqData = {
      pairId: this.pairData._id,
      BotType: "GENIE",
      exchange: this.pairData.botstatus,
      positionId: this.posDetails?._id ? this.posDetails?._id : "",
    };

    this.service.closeBot(reqData)
      .subscribe({
        next: (v: any) => {
          this.loader = false
          if (v.status) {
            this.toastr.success(v.message)
          } else {
            this.toastr.success(v.message)
          }
          this.positionDetails.setPosition({})
        },
        error: (err: any) => {
          this.loader = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          }
        }
      })
  }
  handleSellSubmit() {
    this.loader = true
    let pairName = `${this.pairData.firstCurrencySymbol}${this.pairData.secondCurrencySymbol}`
    // try {
    let reqData = {
      pair: pairName,
      botType: "GENIE",
      exchange: this.pairData.botstatus,
    };

    this.service.getTotQuant(reqData)
      .subscribe({
        next: (v: any) => {
          let finalQty = 0;
          let result = v?.result
          for (let item of result.filled) {
            finalQty += item.quantity;
          }

          let reqDatasell = {
            pairName: pairName,
            amount: finalQty,
            exchange: this.pairData.botstatus,
            BotType: "GENIE",
            formType: "sellGenie",
          };
          this.service.comManualSell(reqDatasell).subscribe({
            next: (v: any) => {
              this.toastr.success(v.message)
              this.positionDetails.setPosition({})
              window.location.reload()
            },
            error: (err: any) => {
              this.loader = false
              if (err.error.message) {
                this.toastr.error(err.error.message)
              }
            }
          })
        },
        error: (err: any) => {
          this.loader = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          }
        }
      })
  }
}
