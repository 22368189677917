<header class="header header--fixed">
  <div class="container-fluid">
    <div class="header__content">
      <nav class="navbar bg-body-dark navbar-expand-xl">
        <a href="/" class="header__logo">
          <img
            [src]="
              theme == 'dark'
                ? 'assets/images/new-logo.svg'
                : 'assets/images/new-logo.svg'
            "
            alt=""
          />
          <!-- <img [src]="theme == 'dark' ? 'assets/images/logo-light.svg': 'assets/images/logo.svg'" alt="Logo"
            class="img-fluid" /> -->
        </a>
        <div class="dash_top_header">
          <button class="header__btn" type="button">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <app-theme class="dashboard_theme"></app-theme>
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <span
                class="withdraw_btn cursor-pointer"
                (click)="switchNearMalls()"
                >Utility Services</span
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/launchpad"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Launchpad</a
              >
            </li>
            
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/subscriptionpage"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Subscription</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/market-inner"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Market</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/fee-inner"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Fees</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/spot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Trade</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex"
                routerLink="/notification"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Notification
                <span class="notify_count mx-1" *ngIf="unread_count > 0">{{
                  unread_count
                }}</span>
              </a>
            </li>
            <li class="nav-item mobile_none">
              <button
                class="btn startButton"
                data-bs-toggle="modal"
                data-bs-target="#new_bot_modal"
              >
                <img
                  [src]="
                    theme == 'dark'
                      ? 'assets/images/startboticon.svg'
                      : 'assets/images/startboticon.svg'
                  "
                  alt="Bot Status"
                  class="img-fluid"
                />
                Start New Bot
              </button>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/buy-crypto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Buy Crypto</a
              >
            </li>
            <li>
              <app-theme></app-theme>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
<div
  class="modal fade primary_modal"
  id="new_bot_modal"
  tabindex="-1"
  aria-labelledby="new_bot_modalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="new_bot_modalLabel">Start new bot</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="new_bot_panel">
          <a href="/dcabot">
            <div class="new_bot_panel_content">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="10" fill="#28BA74"></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24 28.8c-.66 0-1.2-.54-1.2-1.2V8.4c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v19.2c0 .66-.54 1.2-1.2 1.2Zm-13.2-1.2v-4.8c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v4.8c0 .66-.54 1.2-1.2 1.2-.66 0-1.2-.54-1.2-1.2Zm6-12v12c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2v-12c0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2Z"
                  fill="#fff"
                ></path>
              </svg>
              <h4>DCA Bot</h4>
            </div>
            <p>
              The Dollar-cost Averaging bot multiplies your gains and reduces
              risks in volatile markets.
            </p>
          </a>
          <a href="javascript:void(0)">
            <div class="new_bot_panel_content">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="10" fill="#6552EC"></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.6 8.1H8.4c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2h19.2c.66 0 1.2-.54 1.2-1.2V9.3c0-.66-.54-1.2-1.2-1.2ZM8.4 15.3h19.2c.66 0 1.2.54 1.2 1.2v1.2c0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2v-1.2c0-.66.54-1.2 1.2-1.2Zm0 6h19.2c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2Zm-.6 4.8h20.4c.336 0 .6.264.6.6 0 .336-.264.6-.6.6H7.8a.594.594 0 0 1-.6-.6c0-.336.264-.6.6-.6Z"
                  fill="#fff"
                ></path>
              </svg>
              <h4>GRID Bot</h4>
            </div>
            <p>
              The proven grid strategy bot maximizes your returns on the
              slightest price changes.
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
