import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient) { }

  getAssetData() {
    return this.http.get(environment.walletApiUrl + 'api/wallet/getAssetsDetails')
  }

  getCurrency() {
    return this.http.get(environment.walletApiUrl + 'api/currency/getCurrency')
  }

  getAssetByCurrency(currencyId: string) {
    return this.http.get(environment.walletApiUrl + 'api/wallet/getAsset/' + currencyId)
  }

  coinWithdraw(data: any) {
    return this.http.post(environment.walletApiUrl + 'api/wallet/coinWithdraw', data)
  }

  coinRequestVerify(data: any) {
    return this.http.patch(environment.walletApiUrl + 'api/wallet/coinWithdraw', data)
  }

  withdrawCancel(data: any) {
    return this.http.put(environment.walletApiUrl + 'api/wallet/coinWithdraw', data)
  }

  apiFiatDepositRequest(data: any) {
    return this.http.post(environment.walletApiUrl + 'api/wallet/fiatDeposit', data)
  }

  apiWithdrawRequestFiat(data: any) {
    return this.http.post(environment.walletApiUrl + 'api/wallet/fiatWithdraw', data)
  }

  apifiatRequestVerify(data: any) {
    return this.http.patch(environment.walletApiUrl + 'api/wallet/fiatWithdraw', data)
  }

  gettxnhistory(params: any) {
    return this.http.get(environment.walletApiUrl + `api/wallet/history/transaction`, { params })
  }

  userDeposit() {
    return this.http.get(environment.walletApiUrl + `api/wallet/userDeposit`)
  }
  getTDSRateForFiatWithdraw()
  {
    return this.http.get(environment.walletApiUrl + `api/tds/getTDSRateForFiatWithdraw`)
  }

  fiatDeposit(amount: string, image: File, bankPaymentType: string, transactionId: string, coinName: any , phoneNo:any   , token:any , pairId:any): Observable<any> {



    const formData = new FormData();

    formData.append('amount', amount);  // Updated key here
    formData.append('image', image);
    formData.append('bankPaymentType', bankPaymentType);
    formData.append('transactionId', transactionId);
    formData.append('coin', coinName);
    formData.append('pairId' , pairId)
    formData.append('phoneNo' , phoneNo)

    const headers = new HttpHeaders({
      'Authorization': token
    });

    return this.http.post(environment.walletApiUrl + `api/wallet/fiatDeposit/forbuy/coin`, formData , {headers});
  }

  addKycAmlData(data: any) {
    return this.http.post(environment.userApiUrl + 'api/user/kyc/aml', data)
  }

  withdrawRequestStakedCoins(data: any) {
    return this.http.post(environment.walletApiUrl + 'api/wallet/stakedCoinsWithdrawReqest', data)
  }


}
