import { Component, Input } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { fraction, longDecimals, nFormatter, toFixed, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { PairFacade } from 'src/app/store/botpair';
import { ActivatedRoute, Router } from '@angular/router';
import { BotService } from 'src/app/services/bot.service';
import { TradePairFacade } from 'src/app/store/bottradepair';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthFacade } from 'src/app/store/auth';
import { WalletService } from 'src/app/services/wallet.service';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import isEmpty from 'src/app/lib/isEmpty';
import { ToastrService } from 'ngx-toastr';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
@Component({
  selector: 'app-dcabotorder',
  templateUrl: './dcabotorder.component.html',
  styleUrls: ['./dcabotorder.component.css']
})
export class DcabotorderComponent {
  @Input() botDetails: any
  sliderVal: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  amtMultiplier: number = 0;
  amtMultiplierOptions: Options = {
    floor: 0,
    ceil: 2
  };
  stepMultiplier: number = 0;
  stepMultiplierOptions: Options = {
    floor: 0,
    ceil: 2
  };

  toFixedDown = toFixedDown
  toFixed = toFixed
  truncateDecimals = truncateDecimals
  longDecimals = longDecimals
  fraction = fraction
  nFormatter = nFormatter

  pairs: any = {}
  wallet: any = {}
  tradedata: any = {}
  loader = false
  showNext = false
  createDetails = {}
  orderPlaceDet = {}
  auth: any = {}
  searchPair: string = ''
  pairData: any = {}
  formValue: any = {
    investment: 0,
    Iniinvestment: 0,
    takeprofitRatio: 2,
    takeprofitCallback: 0,
    buyinCallback: 0,
    stopLoss: 1,
    isstoploss: false,
    avgCallDrop: 0,
    ratio: 0,
    firstbuyAmount: 0,
    avgOrdAmt: 0,
    slvalue: [0],
    slvaluemanual: ['0', Validators.required],
    margincallLimit: 2,
    amtMultiplier: 1,
    stepMultiplier: 1,
    avgOrdStep: 1,
    convertPrice: 0,
    quantity: ['', Validators.required],
    total: ['']
  }
  // form: FormGroup
  serverErrors: any = {}
  inputFields: any = []
  usdPrice: any = 0.9999
  constructor(
    private _fb: FormBuilder,
    private pairFacade: PairFacade,
    private botFacade: TradePairFacade,
    private router: Router,
    private route: ActivatedRoute,
    private service: BotService,
    private authFacade: AuthFacade,
    private walletService: WalletService,
    private tradeAssetFacade: TradeAssetFacade,
    private positionDetails: PositionDetailsFacade,
    private toastr: ToastrService,
  ) {
    // this.form = this._fb.group({
    //   investment: [0],
    //   Iniinvestment: [0],
    //   takeprofitRatio: [0],
    //   takeprofitCallback: [0],
    //   buyinCallback: [0],
    //   stopLoss: [1],
    //   isstoploss: [false],
    //   avgCallDrop: [0],
    //   ratio: [0],
    //   firstbuyAmount: [0],
    //   avgOrdAmt: [0],
    //   slvalue: [100],
    //   slvaluemanual: ['0', Validators.required],
    //   margincallLimit: [2],
    //   amtMultiplier: [0],
    //   stepMultiplier: [0],
    //   avgOrdStep: [0],
    //   convertPrice: ['', Validators.required],
    //   quantity: ['', Validators.required],
    //   total: ['']
    // });
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }
  ngOnInit() {
    console.log(this.botDetails, '-------122')
    if (!isEmpty(this?.botDetails)) {
      console.log(this.botDetails)
    }
    this.getPairs()
    this.getSinglePair()
    this.getWallet()
  }


  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        this.pairs = pair
      },
      error: (err: any) => { }
    })
  }
  getWallet() {
    this.tradeAssetFacade.tradeAsset$.subscribe({
      next: (asset: any) => {
        this.wallet = asset.secondCurrency
      },
      error: (err: any) => { }
    })
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
        this.showNext = false

      },
      error: (err: any) => { }
    })
  }
  handleSearchPair(event: any) {
    this.searchPair = event.target.value.toUpperCase().replace('/', '')
  }
  changePair(pair: any) {
    const newPair = pair.firstCurrencySymbol + '_' + pair.secondCurrencySymbol
    const activePair = this.route.snapshot.params['tikerRoot']
    if (newPair == activePair) return;
    // this.socketService.emit("unSubscribe", activePair.replace("_", ""));
    this.router.navigateByUrl('/dcabot/' + newPair)
    this.service.setTradePair(pair)
    this.positionDetails.setPosition({})
    // this.fetchAssetByCurrency(pair.firstCurrencyId, 'firstCurrency')
    this.fetchAssetByCurrency(pair.secondCurrencyId, 'secondCurrency')
    // this.pairData = pair;
    // this.marketPriceFacade.setSpotMarketPrice(pair)
  }
  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.auth.signedIn) {
      return
    }
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }
  submitForm() {
    try {
      let formValue = this.formValue
      let tradeArr = [];
      let customAmountSetup = [];
      let avgObj;
      // console.log(tradedata, "-----623");
      if (parseFloat(formValue.margincallLimit) > 0) {
        avgObj = {
          AvgorderStep: formValue.avgOrdStep,
          AmountMultiplier: formValue.amtMultiplier,
          stepMultiplier: formValue.stepMultiplier,
        };
        let prev_xvalue = 1;
        let xvalue = 1;
        for (let x = 1; x < formValue.margincallLimit; x++) {
          prev_xvalue = prev_xvalue * formValue.amtMultiplier;
          xvalue = xvalue + prev_xvalue;
        }

        let Buyamount = formValue.avgOrdAmt / xvalue;
        // console.log(xvalue, "---xvalue", formValue.avgOrdAmt);
        let Ratio = formValue.avgOrdStep;
        customAmountSetup.push({
          marginCall: 1,
          Ratio: Ratio,
          Buyamount: Buyamount,
        });

        let prev_amount = Buyamount;
        let prev_ratio = formValue.avgOrdStep;

        for (let i = 1; i < formValue.margincallLimit; i++) {
          prev_amount = prev_amount * formValue.amtMultiplier;
          prev_ratio = prev_ratio * formValue.stepMultiplier;

          customAmountSetup.push({
            marginCall: 1,
            Ratio: prev_ratio,
            Buyamount: prev_amount,
          });
        }
      } else {
        avgObj = {
          AvgorderStep: "",
          AmountMultiplier: "",
          stepMultiplier: "",
        };
      }

      tradeArr.push({
        ...{
          investmentAmount: formValue.investment,
          firstbuyAmount: formValue.firstbuyAmount,
          AveragingorderAmount: formValue.avgOrdAmt,
          margincallLimit: formValue.margincallLimit,
          takeprofitRatio: formValue.takeprofitRatio,
          AverageCallDrop: formValue.avgCallDrop,
          customAmountSetup: customAmountSetup,
          isstoploss: formValue.isstoploss,
          stopLoss: formValue.isstoploss ? parseFloat(formValue.stopLoss) : 0,
        },
        ...avgObj,
      });

      let pairName = `${this.pairData.firstCurrencySymbol}${this.pairData.secondCurrencySymbol}`;

      let reqData = {
        pairName: pairName,
        pairId: this.pairData._id,
        exchange: this.pairData.botstatus,
        BotType: "GENIE",
        RunStatus: false,
        tradesetting: tradeArr,
        orderType: "Limit",
        validate: true
      };
      this.service.dcabotValidation(reqData).subscribe({
        next: (v: any) => {
          this.showNext = true
          let genDet = {
            pairName: `${this.pairData.firstCurrencySymbol}-${this.pairData.secondCurrencySymbol}`,
            investment: formValue.investment,
            firstbuyAmount: formValue.firstbuyAmount,
            avgOrdAmt: formValue.avgOrdAmt,
            margincallLimit: formValue.margincallLimit,
            avgOrdStep: formValue.avgOrdStep,
            amtMultiplier: formValue.amtMultiplier,
            stepMultiplier: formValue.stepMultiplier,
            takeprofitRatio: formValue.takeprofitRatio,
            isstoploss: formValue.isstoploss,
            stopLoss: formValue.stopLoss,
          }
          reqData.validate = false
          this.createDetails = genDet
          this.orderPlaceDet = reqData
        },
        error: (err: any) => {
          console.log(err, '-------262')
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            if (!isEmpty(validationErrors)) {
              this.serverErrors = validationErrors || {};
              this.toastr.error(err.error.message)
              if (err?.error?.order) {
                this.toastr.error(err.error.order)
              }
            }
          } else if (err.status === 500) {
            this.toastr.error(err.error.message);
          }
        },
        complete: () => { },
      });
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  navigateToLogin() {
    this.router.navigateByUrl('signin')
  }

  handleBSChange(event: any) {
    const { name, value } = event.target;
    let slvaluemanual = toFixedDown(
      (parseFloat(value) / parseFloat(this.wallet?.spotBal)) * 100,
      2
    );
    this.sliderVal = slvaluemanual
    this.formValue({
      [name]: value,
      convertPrice: toFixedDown(
        parseFloat(value) *
        parseFloat(this.usdPrice),
        2
      )
    });
  };
  onSliderChange(newValue: number) {
    let balanceinEx = parseFloat(this.wallet?.spotBal);
    let value = toFixedDown(
      (balanceinEx * newValue) / 100
    )
    this.sliderVal = newValue
    console.log(value, '------311', newValue)
    this.formValue = {
      ...this.formValue, ...{
        investment: value,
        convertPrice: toFixedDown(
          parseFloat(value) *
          parseFloat(this.usdPrice),
          2
        )
      }
    };
  }
  handleIncDec(name: any, sign: any, step: any) {
    let formValue = this.formValue
    if (sign == "+" && name == "margincallLimit") {
      formValue.margincallLimit = parseFloat(formValue.margincallLimit) + parseFloat(step);
      console.log(formValue.margincallLimit, '------207')
      if (formValue.margincallLimit >= 1) {
        formValue = { ...formValue, ...{ [name]: formValue.margincallLimit } };
        this.formValue = {
          ...formValue, ...{ [name]: formValue.margincallLimit }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 1 }
        }
        this.customAmountSetup(formValue);
      }
    } else if (sign == "-" && name == "margincallLimit") {
      formValue.margincallLimit = parseFloat(formValue.margincallLimit) - parseFloat(step);

      if (formValue.margincallLimit >= 1) {
        formValue = { ...formValue, ...{ [name]: formValue.margincallLimit } };
        this.formValue = {
          ...formValue, ...{ [name]: formValue.margincallLimit }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 1 }
        }
        this.customAmountSetup(formValue);
      }
    } else if (sign == "+" && name == "avgOrdStep") {
      formValue.avgOrdStep = parseFloat(formValue.avgOrdStep) + parseFloat(step);
      formValue.avgOrdStep = toFixed(formValue.avgOrdStep, 1);

      if (formValue.avgOrdStep >= 0.5) {
        formValue = { ...formValue, ...{ [name]: parseFloat(formValue.avgOrdStep) } };
        this.formValue = {
          ...formValue, ...{ [name]: parseFloat(formValue.avgOrdStep) }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 0.5 }
        }
        this.customAmountSetup(formValue);
      }
    } else if (sign == "-" && name == "avgOrdStep") {
      formValue.avgOrdStep = parseFloat(formValue.avgOrdStep) - parseFloat(step);
      formValue.avgOrdStep = toFixed(formValue.avgOrdStep, 1);

      if (formValue.avgOrdStep >= 0.5) {
        formValue = { ...formValue, ...{ [name]: parseFloat(formValue.avgOrdStep) } };
        this.formValue = {
          ...formValue, ...{ [name]: parseFloat(formValue.avgOrdStep) }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 0.5 }
        }
        this.customAmountSetup(formValue);
      }
    } else if (sign == "+" && name == "stopLoss") {
      formValue.stopLoss = parseFloat(formValue.stopLoss) + parseFloat(step);
      formValue.stopLoss = toFixed(formValue.stopLoss, 2);

      if (formValue.stopLoss > 0) {
        formValue = { ...formValue, ...{ [name]: parseFloat(formValue.stopLoss) } };
        let slpnl =
          (parseFloat(formValue.investment) * parseFloat(formValue.stopLoss)) / 100;

        formValue = {
          ...formValue,
          ...{ slpnl: toFixedDown(slpnl, 2) },
        };
        this.formValue = {
          ...formValue, ...{ [name]: parseFloat(formValue.stopLoss) }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 0.01 }
        }
        this.customAmountSetup({ ...formValue, ...{ [name]: 0.01 } });
      }
    } else if (sign == "-" && name == "stopLoss") {
      formValue.stopLoss = parseFloat(formValue.stopLoss) - parseFloat(step);
      formValue.stopLoss = toFixed(formValue.stopLoss, 2);

      if (formValue.stopLoss > 0) {
        formValue = { ...formValue, ...{ [name]: parseFloat(formValue.stopLoss) } };
        let slpnl =
          (parseFloat(formValue.investment) * parseFloat(formValue.stopLoss)) / 100;
        formValue = {
          ...formValue,
          ...{ slpnl: toFixedDown(slpnl, 2) },
        };
        this.formValue = {
          ...formValue, ...{ [name]: parseFloat(formValue.stopLoss) }
        }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 0.01 }
        }
        this.customAmountSetup({ ...formValue, ...{ [name]: 0.01 } });
      }
    } else if (sign == "+" && name == "takeprofitRatio") {
      formValue.takeprofitRatio = parseFloat(formValue.takeprofitRatio) + parseFloat(step);
      formValue.takeprofitRatio = toFixed(formValue.takeprofitRatio, 2);

      if (formValue.takeprofitRatio >= 0.3) {
        formValue = {
          ...formValue,
          ...{ [name]: parseFloat(formValue.takeprofitRatio) },
        };

        let tppnl =
          (parseFloat(formValue.investment) *
            parseFloat(formValue.takeprofitRatio)) /
          100;
        console.log(tppnl, '--------467')
        formValue = {
          ...formValue,
          ...{ tppnl: toFixedDown(tppnl, 2) },
        };
        console.log(formValue, '-------472')
        this.formValue = {
          ...formValue, ...{
            [name]: parseFloat(formValue.takeprofitRatio),
          }
        };

        this.customAmountSetup(formValue);
      } else {
        this.formValue = {
          ...formValue, ...{ [name]: 0.3 }
        }
        this.customAmountSetup({ ...formValue, ...{ [name]: 0.3 } });
      }
    } else if (sign == "-" && name == "takeprofitRatio") {
      formValue.takeprofitRatio = parseFloat(formValue.takeprofitRatio) - parseFloat(step);
      formValue.takeprofitRatio = toFixed(formValue.takeprofitRatio, 2);

      if (formValue.takeprofitRatio >= 0.3) {
        formValue = {
          ...formValue,
          ...{ [name]: parseFloat(formValue.takeprofitRatio) },
        };

        let tppnl =
          (parseFloat(formValue.investment) *
            parseFloat(formValue.takeprofitRatio)) /
          100;

        formValue = {
          ...formValue,
          ...{ tppnl: toFixedDown(tppnl, 2) },
        };


        this.formValue = { ...formValue, ...{ [name]: parseFloat(formValue.takeprofitRatio) } }
        this.customAmountSetup(formValue);
      } else {
        this.formValue = { ...formValue, ...{ [name]: 0.3 } }
        this.customAmountSetup({ ...formValue, ...{ [name]: 0.3 } });
      }
    }
  };
  handleTradeChange(e: any): void {
    let { name, value } = e.target
    let formValue = this.formValue;
    let nameu: string, valueu: any, nameus: string, nameum: string;

    if (
      ['firstbuyAmount', 'takeprofitRatio', 'takeprofitCallback', 'buyinCallback', 'stopLoss', 'investment', 'avgOrdAmt', 'avgOrdStep', 'amtMultiplier', 'stepMultiplier', 'margincallLimit', 'slvalue'].includes(name)
    ) {
      if (/[^0-9.]/.test(value)) return;

      const valchk = value.toString();
      const matches = valchk.match(/\./g);
      if (matches != null && matches.length >= 2) {
        return;
      }
    }

    if (name === 'margincallLimit') {
      if (value == 0 || isEmpty(value)) {
        nameu = 'amtMultiplier';
        formValue = { ...formValue, [nameu]: 1 };
        nameus = 'stepMultiplier';
        formValue = { ...formValue, [nameus]: 1 };
        this.customAmountSetup(formValue);
      }
    }
    console.log(name, '------537')
    if (name === 'investment') {
      const fbuy = this.toFixed((value * 40) / 100, 2);
      const avgbuy = this.toFixed((value * 60) / 100, 2);

      formValue = { ...formValue, avgOrdAmt: avgbuy, firstbuyAmount: fbuy, margincallLimit: 2 };

      const tppnl = (parseFloat(value) * parseFloat(formValue.takeprofitRatio)) / 100;
      formValue.tppnl = this.toFixedDown(tppnl, 2);

      const slpnl = (parseFloat(value) * parseFloat(formValue.stopLoss)) / 100;
      formValue.slpnl = this.toFixedDown(slpnl, 2);

      const slvalue = this.toFixedDown((parseFloat(value) / parseFloat(this.wallet?.spotBal)) * 100, 2);
      formValue.slvalue = slvalue;
      formValue.convertPrice = toFixedDown(
        parseFloat(value) *
        parseFloat(this.usdPrice),
        2
      );
      // this.sliderVal = slvalue
    }

    if (name === 'firstbuyAmount') {
      const inv = parseFloat(value) + parseFloat(formValue.avgOrdAmt);
      const sliderPer = (inv / parseFloat(formValue.Iniinvestment)) * 100;
  
      if (value > 0) {
        valueu = parseFloat(formValue.avgOrdAmt || 0) + parseFloat(value);
        formValue = { ...formValue, investment: valueu, slvalue: this.toFixed(sliderPer, 2) };
      } else {
        formValue = { ...formValue, investment: '', slvalue: 0, margincallLimit: 2, avgOrdAmt: 0 };
      }
    }

    if (name === 'avgOrdAmt') {
      const inv = parseFloat(value) + parseFloat(formValue.firstbuyAmount);
      const sliderPer = (inv / parseFloat(formValue.firstbuyAmount)) * 100;

      if (value > 0) {
        valueu = parseFloat(formValue.firstbuyAmount || 0) + parseFloat(value);
        formValue = { ...formValue, investment: valueu, slvalue: this.toFixed(sliderPer, 2) };
      } else {
        formValue = { ...formValue, investment: '', slvalue: 0, margincallLimit: 2, firstbuyAmount: 0 };
      }
    }

    
    console.log(value, '------575')
    this.formValue = { ...formValue, ...{ [name]: value } }
    this.customAmountSetup(formValue);

    if (value) {
      this.serverErrors = {};
      this.loader = false;
    }
  }
  customAmountSetup(formValue: any): void {
    let cusAmtSetup: any = [];
    if (
      formValue.avgOrdAmt <= 0 ||
      isEmpty(formValue.margincallLimit) ||
      formValue.margincallLimit == 0
    ) {
      this.inputFields = cusAmtSetup;
      // onChange({
      //   ...formValue,
      //   cusAmtSetup,
      // });
    } else {
      let prev_xvalue = 1;
      let xvalue = 1;
      for (let x = 1; x < formValue.margincallLimit; x++) {
        prev_xvalue = prev_xvalue * formValue.amtMultiplier;
        xvalue = xvalue + prev_xvalue;
      }

      let Buyamount = formValue.avgOrdAmt / xvalue;
      // console.log(xvalue, "---xvalue", formValue.avgOrdAmt);
      let Ratio = formValue.avgOrdStep;
      cusAmtSetup.push({
        marginCall: 1,
        Ratio: Ratio,
        Buyamount: Buyamount,
      });

      let prev_amount = Buyamount;
      let prev_ratio = formValue.avgOrdStep;

      for (let i = 1; i < formValue.margincallLimit; i++) {
        prev_amount = prev_amount * formValue.amtMultiplier;
        prev_ratio = prev_ratio * formValue.stepMultiplier;

        cusAmtSetup.push({
          marginCall: 1,
          Ratio: prev_ratio,
          Buyamount: prev_amount,
        });
      }
      // console.log(cusAmtSetup, "cusAmtSetup");
      this.inputFields = cusAmtSetup;
      // onChange({
      //   ...tradedata,
      //   cusAmtSetup,
      // });
    }
  }
  handlestopLoss(e: any) {
    this.formValue = { ...this.formValue, ...{ ['isstoploss']: e.target.checked } }
    // onChange({
    //   ...formValue,
    //   customAmountSetup: inputFields,
    // });
  };
}

