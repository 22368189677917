<div class="bot-order" *ngIf="!isClose && !isPrevious">
  <div class="back-drop" [ngStyle]="{
      'opacity': isCollapsed ? 1 : 0.3
    }" *ngIf="!isModalOpen">
    <div class="bot-order-head">
      <h4>Bot actions</h4>
      <button class="bot-clear" (click)="viewPrevious()">
        <img src="assets/images/botclear.svg" alt="close" title="close" />
      </button>
    </div>
    <div class="bot_preview_box_wrapper">
      <!-- <a href="#">
        <div class="bot_preview_box">
          <div>
            <h5>Manual position averaging</h5>
            <p>
              Average the price of a position by manually adding funds according
              to the current market price.
            </p>
          </div>
        </div>
      </a>
      <a href="#">
        <div class="bot_preview_box">
          <div>
            <h5>Modify Bot</h5>
            <p>Modify the current bot settings.</p>
          </div>
        </div>
      </a>
      <a href="#">
        <div class="bot_preview_box">
          <div>
            <h5>Stop Bot</h5>
            <p>Stop the bot's operations completely.</p>
          </div>
        </div>
      </a>
      <a href="#">
        <div class="bot_preview_box">
          <div>
            <h5>Deactivate Bot</h5>
            <p>Deactivate the bot's operations completely.</p>
          </div>
        </div>
      </a> -->
      <!-- <a > -->
      <div class="bot_preview_box" (click)="viewClose('close')">
        <div>
          <h5>Close Bot</h5>
          <p>Cancel all orders & Open orders will be closed
            and the current amount in trade currency will be
            held in your balance..</p>
        </div>
      </div>
      <div class="bot_preview_box" (click)="viewClose('sellMarket')">
        <div>
          <h5>Sell at Market Price</h5>
          <p>
            Sell {{sellAmount}} {{pairData.firstCurrencySymbol}} for
            {{pairData.secondCurrencySymbol}} at market price and
            move the bot to history.
          </p>
        </div>
      </div>
      <!-- </a> -->
    </div>
  </div>
  <div class="bgcolor d-flex align-items-center gap-2 collapse_btn">
    <!-- Collapsible Content -->
    <div class="collapsible-content" [@contentAnimation]="isCollapsed ? 'hidden' : 'visible'" *ngIf="!isCollapsed">
      <div class="bot-order-head">
        <h4>Bot actions</h4>
        <button class="bot-clear" (click)="toggleCollapse()">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <!-- <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Manual position averaging</h5>
              <p>
                Average the price of a position by manually adding funds
                according to the current market price.
              </p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Modify Bot</h5>
              <p>Modify the current bot settings.</p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Stop Bot</h5>
              <p>Stop the bot's operations completely.</p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Deactivate Bot</h5>
              <p>Deactivate the bot's operations completely.</p>
            </div>
          </div>
        </a> -->
        <!-- <a href="#"> -->
        <div class="bot_preview_box">
          <div>
            <h5>Close Bot</h5>
            <p>Cancel all orders & Open orders will be closed
              and the current amount in trade currency will be
              held in your balance..</p>
          </div>
        </div>
        <div class="bot_preview_box">
          <div>
            <h5>Sell at Market Price</h5>
            <p>Stop the bot's operations completely.</p>
          </div>
        </div>
        <!-- </a> -->
      </div>
      <!-- "Hide Content" Button -->
      <button class="continue bottom-btn" (click)="toggleCollapse()">
        Hide Content
      </button>
    </div>

    <!-- "Show Content" Button -->
    <button class="toggle-btn continue" *ngIf="isCollapsed" (click)="toggleCollapse()">
      Show Content
    </button>
    <!-- <button class="toggle-btn continue" [disabled]="loader" (click)="openModal()">
      Cancel Order
    </button> -->
  </div>
</div>

<app-dcaorderplace *ngIf="isClose" orderStep="four" [posDetails]="posDetails"
  [closeType]="closeType"></app-dcaorderplace>
<app-dcaorderplace *ngIf="isPrevious" orderStep="five"></app-dcaorderplace>
<!-- Modal Structure -->
<div *ngIf="isModalOpen" class="modal-overlay">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Cancel Order</h1>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">X</button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Are you sure you want to cancel this Position?</label>
        </div>
        <div>
          <button class="trade_buy_btn my-2" type="button" (click)="cancelOrder()">Confirm</button>
          <button class="trade_sell_btn" type="button" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>