import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TradePairFacade, TradePairState } from '../store/bottradepair';

@Injectable({
    providedIn: 'root'
})
export class BotService {
    constructor(
        private http: HttpClient,
        private tradePairFacade: TradePairFacade
    ) { }

    getPairList() {
        return this.http.get(environment.botApiUrl + 'api/bot/tradePair')
    }
    getOpenOrders(params: any) {
        return this.http.get(environment.botApiUrl + 'api/bot/getOpenOrders', { params })
    }
    getTotQuant(params: any) {
        return this.http.get(environment.botApiUrl + 'api/bot/get-total-quant', { params })
    }
    getTradeHistory(params: any) {
        return this.http.get(environment.botApiUrl + 'api/bot/getTradeHistory', { params })
    }
    dcabotValidation(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/dca-bot-validation', payload)
    }
    comManualSell(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/com-manual-sell', payload)
    }
    getBotHistory(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/getBotHistory', payload)
    }
    closeBot(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/close-bot', payload)
    }
    getOpenOChart(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/getOpenOrdersChart', payload)
    }
    dcaOrderPlace(payload: any) {
        return this.http.post(environment.botApiUrl + 'api/bot/dca-orderplace', payload)
    }
    setTradePair({
        firstCurrencySymbol,
        firstCurrencyId,
        firstFloatDigit,
        secondCurrencySymbol,
        secondCurrencyId,
        secondFloatDigit,
        botstatus,
        tikerRoot,
        _id
    }: TradePairState) {
        this.tradePairFacade.setTradePair(
            {
                firstCurrencySymbol,
                firstCurrencyId,
                firstFloatDigit,
                secondCurrencySymbol,
                secondCurrencyId,
                secondFloatDigit,
                botstatus,
                tikerRoot,
                _id
            })
    }
}